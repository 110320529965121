<template>
  <div class="req-consent">
    <div class="container">
      <RoleCard role="school">
        <template #label>Доступ заблокирован</template>
        <template #body>
          <div class="row">
            <div class="col-md-7 col-sm-8 col-xs-12">
              <p>
                К&nbsp;сожалению, ваш Личный кабинет в&nbsp;НТО был заблокирован
                на текущий учебный год. Это означает, что
                вы&nbsp;не&nbsp;сможете принять участие в&nbsp;олимпиаде. Если
                вы&nbsp;хотите узнать причину блокировки, напишите на&nbsp;<a
                  :href="`mailto:${$store.state.supportEmail}`"
                  class="link"
                  >{{ $store.state.supportEmail }}</a
                >
              </p>
            </div>
          </div>
        </template>
      </RoleCard>
    </div>
  </div>
</template>

<script>
import RoleCard from "@/components/registration/RoleCard.vue";
export default {
  name: "BannedParticipant",
  components: {
    RoleCard,
  },
  metaInfo() {
    return {
      title: "Вы заблокированы",
    };
  },
};
</script>
