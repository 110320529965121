import { request, talentRequest } from "@/services/api";

const EVENT_REQUEST_EXIST_ERROR =
  "Пользователь уже подал заявку на это мероприятие";

/**
 * Удаляем существующую заявку на профиль в таланте
 * @param {number[]} events - массив ид мероприятий
 * @param {number} talentId
 */
const cleanUpRequests = async (events, talentId) => {
  /**
   * 1. Получаем заявки на мероприятия
   */
  const { data } = await talentRequest({
    method: "GET",
    url: `/api/users/${talentId}/requests/`,
    params: {
      events: events.join(","),
    },
  });

  if (!data.results?.length) {
    /**
     * Ошибка при выборе профиля про наличие заявки есть, но заявка не найдена
     */
    throw new Error(
      "Не удалось найти заявку на мероприятие. Пожалуйста, сообщите нам об этой ошибке"
    );
  } else if (data.results.length > 1) {
    /**
     * Если заявок несколько, то не понятно какую удалять.
     * Удалять все - не вариант
     */
    throw new Error(
      "Найдено больше одной заявки на мероприятие. Пожалуйста, сообщите нам об этой ошибке"
    );
  }

  /**
   * 2. Удаляем заявку на ивент
   */
  await talentRequest({
    method: "DELETE",
    url: `/api/users/${talentId}/requests/${data.results[0].id}/`,
  });
};

export const ProfileService = {
  /**
   * Выбор профиля участником олимпиады
   * @param {object} payload
   * @param {number} payload.talentId
   * @param {number} payload.profile_id - id профиля
   * @param {number=} payload.final_event - ид финала для джунов
   * @param {object[]} payload.steps - массив этапов профиля
   * @param {'TEAM' | 'INDIVIDUAL'} payload.stage - этап на который регистрируется юзер, для студентов TEAM для остальных 'INDIVIDUAL'
   */
  async participantSelectProfile({
    talentId,
    profile_id,
    final_event,
    steps,
    stage,
  }) {
    if (!talentId) throw new Error("Не передан обязательный параметр talentId");
    if (!steps?.length) {
      throw new Error(
        `Не удалось выбрать профиль ${profile_id}, т.к. у него отсутствуют этапы. Пожалуйста, сообщите нам об этой ошибке.`
      );
    }
    const stageSteps = steps.filter((step) => step.stage === stage);
    if (!stageSteps.length) {
      throw new Error(
        `Не удалось выбрать профиль ${profile_id}, т.к. у него отсутствует этап ${stage}. Пожалуйста, сообщите нам об этой ошибке.`
      );
    }
    const eventIds = stageSteps.map((step) => step.talent_event_id);
    if (!eventIds.length) {
      throw new Error(
        `Не удалось выбрать профиль ${profile_id}, т.к. в этапе ${stage} не указан id мероприятия. Пожалуйста, сообщите нам об этой ошибке.`
      );
    }

    const requestConfig = {
      url: `/profiles/${profile_id}`,
      method: "POST",
      data: {
        ...(final_event && { final_event }),
      },
    };
    try {
      const { data } = await request(requestConfig);
      return data;
    } catch (error) {
      /**
       * Если ошибка при выборе профиля связана с наличием заявки
       * то нужно эту заявку удалить. Т.к. она возникает по причиине
       * несовершенства АПИ и иначе как в ручном режиме ее не исправить.
       */
      if (error?.message === EVENT_REQUEST_EXIST_ERROR) {
        await cleanUpRequests(eventIds, talentId);
        const { data } = await request(requestConfig);
        return data;
      }
      throw error;
    }
  },
};
