import { getScrollBarWidth } from "@/utils";
// устанавливаю размер скроллбара в css prop

try {
  const width = getScrollBarWidth();
  const root = document.documentElement;
  root.style.setProperty("--scrollbar-width", width + "px");
} catch (error) {
  // do nothing
}
