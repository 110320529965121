/**
 * список привилегий которые нужно "схопнуть" в 1
 */
const collapseMap =
  process.env.VUE_APP_ENV === "production"
    ? [
        [351, 350],
        [351, 347],
        [354, 353],
        [354, 352],
      ]
    : [
        [33, 34],
        [32, 34],
      ];

const isExpired = (date) => {
  if (!date) return false;
  const now = Date.now();
  const d = new Date(date).getTime();
  return now > d;
};

const shouldBeMerged = (group) => {
  const usedProfiles = group.map((n) => n.profile_id).filter(Boolean);
  /**
   * если есть поюбзанные то смотрим в один ли профиль
   */
  if (usedProfiles.length) {
    return usedProfiles[0] === usedProfiles[1];
  }
  /**
   * Схлапываем только привилегии по БАЛЛАМ
   */
  const source = group[1];
  const target = group[0];

  const isSourceExpired = isExpired(source.expired_at);
  const isTargetExpired = isExpired(target.expired_at);

  /**
   * Если обе протухли - то можно схлопнуть
   */
  if (isSourceExpired && isTargetExpired) {
    return true;
  }
  /**
   * Если протухла только одна - то нельзя
   */
  if (isSourceExpired !== isTargetExpired) {
    return false;
  }
  /**
   * Обе привилегии должны давать баллы за одинаковый этап
   */
  if (
    source.final_pass ||
    source.pass_through ||
    source.final_pass !== target.final_pass ||
    source.pass_through !== target.pass_through ||
    !!source.score !== !!target.score ||
    !!source.team_score !== !!target.team_score
  ) {
    console.log("NOT MATCH");
    return false;
  }
  /**
   * Схлапываем только с одинаковым набором профилей
   */
  const prof1 = source.profiles.sort().join();
  const prof2 = target.profiles.sort().join();
  //   console.log(first);
  return prof1 === prof2;
};

const getGroups = (list) => {
  const mapById = list.reduce((acc, priv) => {
    acc[priv.privilege_id] = priv;
    return acc;
  }, {});

  let groups = [];

  collapseMap.forEach((touple) => {
    const group = touple.map((id) => mapById[id]).filter(Boolean);
    // если нет обоих метчей то переходим к следующей группе
    if (group.length !== touple.length || !shouldBeMerged(group)) {
      return;
    }
    const source = group[1];
    const target = group[0];

    if (source.score) {
      target.score += source.score;
    } else if (source.team_score) {
      target.team_score += source.team_score;
    }
    target._group_with = source.id;
    target._group_with_privilege_id = source.privilege_id;
    groups.push(target);
  });

  if (!groups.length) return list;

  const filterIds = groups.map((n) => [n.id, n._group_with]).flat();
  const restPriv = list.filter((n) => !filterIds.includes(n.id));
  return [...groups, ...restPriv];
};

export const parsePrivilege = (data) => {
  console.log(data);
  if (!data || !data.privilege) return;
  const { id, profile_id, privilege } = data;
  const { id: privilege_id, profiles, ...rest } = privilege;
  const profile_ids = profiles.map((n) => n.id);
  const result = {
    id,
    profile_id,
    privilege_id,
    profiles: profile_ids,
    ...rest,
  };

  return result;
};

export const combinePrivileges = (privList) => {
  const list = privList?.map(parsePrivilege)?.filter(Boolean);
  if (!list || !list.length) return [];
  if (list.length === 1) {
    return list;
  }
  return getGroups(list);
};
