export const ui = {
  namespaced: true,
  state: () => ({
    layout: "default",
    loading: false,
  }),
  mutations: {
    SET_LAYOUT(state, layout) {
      state.layout = layout;
    },
    SET_STATE(state, payload) {
      state[payload.key] = payload.value;
    },
  },
  getters: {
    baseNav: (state, getters, rootState, rootGetter) => {
      if (rootGetter["user/isMentor"]) {
        return rootState.mentor.navigation;
      }
      return rootState.participant.navigation;
    },
  },
};
