<template>
  <label
    :class="classNames"
    class="base-checkbox"
    @click="handleClick">
    <input
      ref="input"
      :checked="shouldBeChecked"
      v-bind="$attrs"
      :value="value"
      :disabled="disabled"
      type="checkbox"
      @change="updateInput" />
    <div class="base-checkbox__box">
      <slot name="icon">
        <svg
          class="base-checkbox__icon"
          viewBox="0 0 15 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.7062 0.291957C15.0972 0.681964 15.0981 1.31513 14.708 1.70617L5.73185 10.7062C5.54424 10.8943 5.28948 11 5.02381 11C4.75813 11 4.50338 10.8943 4.31577 10.7062L0.291957 6.67169C-0.0980494 6.28065 -0.0972108 5.64748 0.29383 5.25747C0.684872 4.86747 1.31804 4.86831 1.70804 5.25935L5.02381 8.58391L13.292 0.29383C13.682 -0.0972108 14.3151 -0.0980494 14.7062 0.291957Z" />
        </svg>
      </slot>
    </div>
    <span
      v-if="$slots.default"
      class="base-checkbox__label">
      <slot v-bind="{ checked: shouldBeChecked }"></slot>
    </span>
  </label>
</template>

<script>
// @see https://kirillurgant.com/notes/creating-custom-inputs-vue-js
export default {
  name: "BaseCheckbox",
  inheritAttrs: false,
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {},
    // eslint-disable-next-line vue/no-unused-properties
    type: String,
    // eslint-disable-next-line vue/require-prop-types
    modelValue: {
      default: false,
    },
    // We set `true-value` and `false-value` to the default true and false so
    // we can always use them instead of checking whether or not they are set.
    // Also can use camelCase here, but hyphen-separating the attribute name
    // when using the component will still work
    trueValue: {
      default: true,
      type: [String, Number, Boolean],
    },
    falseValue: {
      default: false,
      type: [String, Number, Boolean],
    },
    theme: {
      type: String,
      default: "default",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    useClick: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    shouldBeChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }
      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.modelValue === this.trueValue;
    },
    classNames() {
      const { shouldBeChecked, theme, disabled, required } = this;
      return [
        {
          "is-checked": shouldBeChecked,
          "is-disabled": disabled,
          "with-label": this.$slots.default,
          "is-required": required,
        },
        `theme-${theme}`,
      ];
    },
  },
  methods: {
    handleClick(e) {
      if (this.useClick) {
        e.preventDefault();
        this.updateInput({ target: { chacked: this.shouldBeChecked } });
      }

      if (this.pending) {
        e.preventDefault();
        return false;
      }
    },
    updateInput(event) {
      let isChecked = event.target.checked;
      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue];

        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }
        this.$emit("change", newValue);
      } else {
        this.$emit("change", isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
};
</script>
<style lang="less" scoped>
@app-checkbox-bg: #fff;
@app-checkbox-size: 20px;
@app-checkbox-border-color: @input-border-color;
@app-checkbox-border-width: @input-border-width;
@app-checkbox-focus-border-color: @input-focus-border-color;
@app-checkbox-hover-border-color: @input-hover-border-color;
@app-checkbox-border-radius: @input-border-radius;
@app-checkbox-icon-color: #fff;

.base-checkbox {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  user-select: none;
  cursor: pointer;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
    // visibility: collapse;
  }

  &__box {
    display: inline-block;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    width: @app-checkbox-size;
    height: @app-checkbox-size;
    background-color: @app-checkbox-bg;
    transition: background 0.3s, border 0.3s;
    cursor: pointer;
    vertical-align: middle;
    transform: translateZ(0);
    border: @app-checkbox-border-width solid @app-checkbox-border-color;
    border-radius: @app-checkbox-border-radius;
    overflow: hidden;
    flex-grow: 0;
    flex-shrink: 0;

    &:hover,
    &:active {
      border-color: @app-checkbox-hover-border-color;
    }
  }

  &:hover &__box {
    border-color: @app-checkbox-hover-border-color;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 13px;
    margin-top: -7px;
    margin-left: -6px;
    transform-origin: left top;
    transition: opacity 0.3s, transform 0.3s;
    opacity: 0;
    transform: rotateZ(-45deg);
    fill: @app-checkbox-icon-color;
  }

  &.is-checked &__box &__icon {
    opacity: 1;
    transform: rotateZ(0deg);
  }

  &.is-checked &__box {
    border-color: @app-checkbox-focus-border-color;
    background-color: @app-checkbox-focus-border-color;
  }

  &__label {
    margin-left: 0.55em;
    display: inline-block;
    // font-size: 12px;
    // line-height: 22 / 12;
  }
  .with-label &__box {
    margin-right: 0.25em;
  }

  &.with-label {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  // &:not(.is-checked) input:focus + &__box {
  //   // box-shadow: @shadow-focus;
  //   // border-color: @green-200;
  // }

  &.is-disabled {
    cursor: initial;
  }

  &.is-disabled &__box {
    // border-color: @green-300 !important;
    cursor: initial;
  }

  // &.is-disabled.is-checked &__box {
  //   // background-color: @green-300;
  // }

  &.is-required &__label::after {
    content: "*";
    padding-left: 5px;
    // color: @primary-color;
  }
}
</style>
