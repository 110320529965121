<template>
  <div class="pagination">
    <button
      class="pagination__button prev"
      @click="changePage(currentPage - 1)"></button>

    <span>{{ currentPage + 1 }}/{{ allPage }}</span>

    <button
      class="pagination__button next"
      @click="changePage(currentPage + 1)"></button>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    currentPage: Number,
    allPage: Number,
  },
  methods: {
    changePage(newPage) {
      if (newPage < 0 || newPage >= this.allPage) return;
      this.$emit("changePage", newPage);
    },
  },
};
</script>

<style lang="less" scoped>
.pagination {
  display: flex;
  align-items: center;
  margin-left: -10px;
  margin-right: -10px;

  &__button {
    width: 22px;
    height: 22px;
    border: 1px solid @link-blue;
    border-radius: @radius-s;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    transition: background 0.3s, border-color 0.3s;
    margin: 0 10px;

    &::before {
      content: "";
      width: 32px;
      height: 32px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &::after {
      content: "";
      width: 6px;
      height: 6px;
      border-left: 2px solid @link-blue;
      border-bottom: 2px solid @link-blue;
      position: absolute;
      top: 7px;
      left: 9px;
      transform: rotate(45deg);
      transition: border-color 0.3s;
    }

    &:hover {
      background-color: @link-blue;
      border-color: @link-blue;
    }

    &:hover::after {
      border-color: #fff;
    }

    &.next::after {
      transform: rotate(-135deg);
      left: initial;
      right: 9px;
    }
  }
}
</style>
