import { checkNotificationPromise } from "@/utils/notifications";
import Vue from "vue";
const PLUGIN_NOTIFICATION_DURATION = 30 * 1000;

const isSupport = () => {
  return "Notification" in window && "serviceWorker" in navigator;
};
export const notificationsSupported = isSupport();

export const noticeDispatcher = {
  usePlugin: !notificationsSupported,
  permission: window.Notification?.permission,
  /**
   * Вызывает нотификацию в зависимости
   * от разрешения и возможностей браузера.
   * либо через воркер, либо через плагин
   * @param {string} subject - заголовок
   * @param {*} text - контент
   */
  dispatch(subject, text) {
    // если запрещены то быстрый выход
    if (this.permission === "denied") {
      return;
    }
    // если показ уведомлений включен
    if (this.permission === "granted") {
      this.showNotice(subject, text);
    } else if (checkNotificationPromise()) {
      // если доступ не запрашивали, проверим поддерживает
      // ли браузер промис-верисию запроса
      Notification?.requestPermission().then((p) => {
        this.permission = p;
        if (p === "granted") {
          this.showNotice(subject, text);
        }
      });
    }
  },
  showNotice(subject, text) {
    if (this.usePlugin) {
      this.showPluginNotice(subject, text);
      return;
    }

    try {
      this.showNativeNotice(subject, text);
    } catch (error) {
      this.usePlugin = true;
      this.showPluginNotice(subject, text);
    }
  },
  /**
   * Показывает уведомление через сервис-воркер
   * @param {String} subject
   * @param {String} text
   */
  showNativeNotice(subject, text) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.showNotification(subject, {
        body: text,
        vibrate: [200, 100, 200],
      });
    });
  },
  // сохраняет ссылку на плагин с нотификациями
  setPlugin(plugin) {
    this._plugin = plugin;
  },
  showPluginNotice(subject, text) {
    if (typeof Vue.notify !== "function") {
      throw new Error("Notification plugin is not installed");
    }
    Vue.notify({
      group: "base",
      title: subject,
      text: text,
      duration: PLUGIN_NOTIFICATION_DURATION,
    });
  },
};
