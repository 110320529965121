/**
 * Список UTM меток для записи в куку на этапе регистрации пользователя
 */
export const UTM_LABELS = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_content",
  "utm_term",
];

/**
 * Set cookie
 * @param {string} name
 * @param {string|number} value
 * @param {number} days
 */
export function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

/**
 * Get Cookie
 * @param {string} name
 * @returns {string} cookie value
 */
export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function eraseCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

/**
 * Возвращает конкатенированную строку для UTM-куки таланта
 * @param {Object} routeQuery
 * @param {String?} routeQuery.utm_source
 * @param {String?} routeQuery.utm_medium
 * @param {String?} routeQuery.utm_campaign
 * @param {String?} routeQuery.utm_content
 * @param {String?} routeQuery.utm_term
 * @returns {String} строка с параметрами
 */
export const concatUTM = (routeQuery = {}) => {
  if (!routeQuery && typeof routeQuery !== "object") return "";
  let result = "";
  UTM_LABELS.forEach((value) => {
    if (routeQuery[value]) {
      result += encodeURIComponent(`${value}=${routeQuery[value]};`);
    }
  });
  return result;
};

export const setUTMCookie = (query) => {
  const utm = concatUTM(query);
  if (utm) {
    setCookie("utm", utm, 1);
  }
};
