import store from "@/store";
import { MENTOR_ROLE, PARTICIPANT_ROLE } from "@/constants";
import * as Sentry from "@sentry/vue";

/**
 * Получение данных о текущем participant
 */
export const participantMiddleware = async (to, from, next) => {
  if (!to.meta.participant) {
    return next();
  }
  const user = store.getters["user/user"];
  if (user?.role === PARTICIPANT_ROLE && !!user?.participant?.id) {
    try {
      store.commit("ui/SET_STATE", { key: "loading", value: true });
      await Promise.all([
        store.dispatch("getSeason"),
        store.dispatch("getTracks"),
        store.dispatch("profile/getProfiles"),
        store.dispatch("getSchedule"),
      ]);
      await store.dispatch("participant/getInfo");
      store.commit("ui/SET_STATE", { key: "loading", value: false });
      const rConsent = store.getters["user/requireConsentFile"];
      if (rConsent) {
        const redirectPage = {
          name: "require-consent",
          query: {},
        };
        if (to.name !== "require-consent") {
          redirectPage.query.next = to.fullPath;
        }
        return next(redirectPage);
      }
      return next();
    } catch (error) {
      console.log("error: %O", error.message);
      Sentry?.captureException(error, (scope) => {
        scope.setTag("middleware", "participant");
        scope.setTag("url", error?.url);
      });
      store.commit("ui/SET_STATE", { key: "loading", value: false });
      next({
        path: "/",
        query: {
          error: "participant_data",
          status: error?.status,
          url: encodeURIComponent(error?.url),
          msg: encodeURIComponent(error?.message),
        },
      });
    }
  } else {
    if (user?.role === MENTOR_ROLE) {
      return next({ name: "mentor-index" });
    }
    return next("/?error=participant_auth");
  }
};

/**
 * Получение данных о текущем mentor
 */
export const mentorMiddleware = async (to, from, next) => {
  const user = store.getters["user/user"];
  if (!to.meta.mentor) {
    return next();
  }
  if (user?.role === MENTOR_ROLE) {
    try {
      store.commit("ui/SET_STATE", { key: "loading", value: true });
      const requests = [
        store.dispatch("getSeason"),
        store.dispatch("profile/getProfiles"),
        store.dispatch("getTracks"),
        store.dispatch("mentor/getMyOrganizations"),
      ];
      await Promise.all(requests);
      store.commit("ui/SET_STATE", { key: "loading", value: false });
      // эти данные нужны, но агрится на ошибку не надо
      try {
        await Promise.all([
          store.dispatch("mentor/getStudents"),
          store.dispatch("mentor/getFavoritesProfiles"),
        ]);
      } catch (error) {
        // do nothing
      }
      return next();
    } catch (error) {
      Sentry?.captureException(error, (scope) => {
        scope.setTag("middleware", "mentor");
        scope.setTag("url", error?.url);
      });
      console.log("error", error);
      store.commit("ui/SET_STATE", { key: "loading", value: false });
      next("/?error=fetch_mentor_data");
    }
  } else {
    console.log("mentorMiddleware redirected from ", to.fullPath);
    return next("/?error=role_mismatch");
  }
};
