import ErrorModal from "@/modals/ErrorModal";
export default {
  methods: {
    /**
     * Модалка с сообщением об ошибке
     * @param {object} options
     * @param {string=} title - Заголовок
     * @param {string=} content - Текст сообщения
     * @param {string=} message - Текст ошибки которую вернул сервер
     * @param {number=} status - Статускод ошибки
     */
    showErrorModal({ title, message, content, status, url, report }) {
      this.$modal.show(
        ErrorModal,
        {
          title,
          message,
          content,
          status,
          url,
          report,
        },
        {
          adaptive: true,
          scrollable: true,
          minHeight: 200,
          height: "auto",
        }
      );
    },
  },
};
