const IGNORE_ERRORS_STRINGS = ["talent_api_error"];

/**
 *
 * @param {*} value - any
 * @returns {string} - текст первой ошибки
 */
export const getFirstStringValue = (value) => {
  let result;
  const valueType = typeof value;
  if (!value || valueType === "number") return;

  const isArray = Array.isArray(value);

  if (valueType === "string" && !IGNORE_ERRORS_STRINGS.includes(value)) {
    return value;
  }

  if (isArray) {
    for (const item of value) {
      if (!item) continue;
      const string = getFirstStringValue(item);
      if (string && !IGNORE_ERRORS_STRINGS.includes(value)) {
        result = string;
        break;
      }
    }
  }

  if (!isArray && valueType === "object") {
    for (let key in value) {
      if (!value[key]) continue;
      const string = getFirstStringValue(value[key]);
      if (string && !IGNORE_ERRORS_STRINGS.includes(value)) {
        result = string;
        break;
      }
    }
  }
  return result;
};

/**
 * @extends Error
 */
export class APIError extends Error {
  /**
   * @param {Object} error - axios request error
   */
  constructor(error) {
    const status = error?.response?.status || 532;
    let message = `Возникла непредвиденная ошибка (${status})`;

    if (status === 504) {
      message = "Превышено время ожидания ответа от сервера";
    } else if (
      error?.response?.data &&
      typeof error.response.data === "object"
    ) {
      const firstErrorMessage = getFirstStringValue(error.response.data);
      if (firstErrorMessage) message = firstErrorMessage;
      if (firstErrorMessage === "Not Found") {
        message = `Запись не найдена`;
      }
    }

    super(message);
    if (
      error?.response?.headers["content-type"] !== "text/html; charset=utf-8"
    ) {
      this.originalData = error.response?.data;
    } else {
      this.originalData = {};
    }
    this.status = status;
    this.url = error?.request?.responseURL;
  }
}
