<template>
  <div class="box">
    <div class="icon">
      <BaseSpinner
        size="32px"
        brand />
    </div>
    <div class="content">
      <p>
        Идет перенаправление на платформу Яндекс.Контест. Пожалуйста,
        подождите...
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExternalRedirect",
  metaInfo() {
    return {
      title: "Перенаправление на платформу Яндекс.Контест",
    };
  },
};
</script>

<style scoped lang="less">
.box {
  background-color: #fff;
  border-radius: 12px;
  padding: 24px;
  width: 100%;
  max-width: 480px;
  box-shadow: @shadow-default;
  border-left: 4px solid @base-color;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .icon {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 10px;
  }

  .content {
    flex-grow: 1;
    min-width: 1px;
  }
}
</style>
