const BASE_CONFIG = {
  CLIENT_API_PREFIX: "/client/api",
  CLIENT_TALENT_PREFIX: "/client/talent",
  CLIENT_CALCON_PREFIX: "/client/calcon",
  API_PREFIX: "/api",
  TALENT_BASE_URL:
    process.env.VUE_APP_TALENT_BASE_URL || "https://talent.kruzhok.org",
};

export const config = {
  ...BASE_CONFIG,
};
