import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// Регистрация базовых компонентов
import "@/components/base/autoRegistrator";
// Плагины
import "@/plugins/swRegistrator";
import "@/plugins/veevalidate";
import "@/plugins/modals/index.js";
import "@/plugins/gtm";
import "@/plugins/cleave";
import "@/plugins/sentry";
import "@/plugins/scrollbarWitdth";
import "@/plugins/notifications/vue-notifications";
import "@/plugins/vue-meta";
import ShowErrorModal from "@/mixins/ShowErrorModal";

Vue.config.productionTip = true;
Vue.config.devtools = process.env.NODE_ENV === "development";

Vue.mixin(ShowErrorModal);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
