<template>
  <div>
    <Multiselect
      v-if="!isNative"
      ref="select"
      :value="value"
      :class="selectClasses"
      v-bind="bindings"
      v-on="listeners"
      @input="input">
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData">
        <slot
          :name="name"
          v-bind="slotData" />
      </template>
    </Multiselect>
    <!-- Нативный контрол -->
    <div v-else>
      <select
        ref="nativeSelect"
        v-bind="$attrs"
        v-model="nativeValue"
        :class="selectClasses"
        class="base-select"
        @change="handleNativeChange">
        <option
          v-if="bindings.placeholder"
          value=""
          disabled>
          {{ bindings.placeholder }}
        </option>
        <option
          v-for="(item, idx) in bindings.options"
          :key="idx"
          :value="item">
          {{ getTitle(item) }}
        </option>
      </select>
    </div>

    <span
      v-if="errors && errors.length"
      class="form-error"
      >{{ errors[0] }}</span
    >
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/plugins/multiselect.less";
const defaultOptions = {
  showLabels: false,
  openDirection: "bottom",
};
export default {
  name: "BaseSelect",
  components: {
    Multiselect,
  },
  inheritAttrs: false,
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {},
    native: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    // Размер md, sm
    size: {
      type: String,
      default: "md",
    },
    theme: {
      type: String,
      default: "default",
      validator(value) {
        return ["reg", "default"].includes(value);
      },
    },
    settings: {
      type: Object,
    },
    valid: Boolean,
    notEmpty: Boolean,
  },
  data() {
    return {
      nativeValue: "",
    };
  },
  computed: {
    isNative() {
      return this.native;
    },
    listeners() {
      // eslint-disable-next-line no-unused-vars
      const { input, ...rest } = this.$listeners;
      return rest;
    },
    bindings() {
      const { settings = {} } = this;
      const mergedSettings = { ...defaultOptions, ...settings };

      return {
        ...this.$attrs,
        ...mergedSettings,
      };
    },
    isPrimitiveValues() {
      return this.bindings.options.every((option) => {
        return typeof option !== "object";
      });
    },
    selectClasses() {
      const { errors, valid, size, notEmpty, theme } = this;
      const result = {
        "has-error": errors.length,
        "is-valid": valid,
        [`size-${size}`]: true,
        [`theme-${theme}`]: true,
        "not-empty": notEmpty,
      };
      return result;
    },
  },
  watch: {
    value: {
      handler(val) {
        this.nativeValue = val || "";
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      const select = this.$refs.select;
      if (select) {
        const input = select.$el.querySelector("input");
        if (input) {
          input.autocomplete = "new-password";
        }
      }
    });
  },
  created() {
    this.dirtyClose = () => {
      Array.from(document.querySelectorAll(".multiselect *")).forEach((el) => {
        el.blur();
      });
    };
    if (this.bindings.preselectFirst && this.native && !this.nativeValue) {
      this.nativeValue = this.bindings.options[0];
      this.handleNativeChange();
    }
  },
  methods: {
    input(val) {
      this.$emit("input", val);
    },
    getTitle(option) {
      const { label } = this.bindings;
      if (!this.isPrimitiveValues && option) {
        return option[label];
      } else {
        return option;
      }
    },
    handleNativeChange() {
      this.$emit("input", this.nativeValue);
      this.$emit("select", this.nativeValue);
    },
  },
};
</script>

<style lang="less" scoped>
.base-select {
  width: 100%;
  border: @input-border-width solid @input-border-color;
  height: @input-height;
  padding: 0px @input-padding;
  box-shadow: none;
  border-radius: 0;
  font-size: @input-font-size;
  font-family: inherit;
  border-radius: @input-border-radius;
  transition: border-color 0.3s;
  background-color: @input-bg-color;
  display: block;
  box-sizing: border-box;
  color: @input-text-color;
  outline: none;
}
</style>
