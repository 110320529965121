/**
 * Переменные из сервиса Нотификаций
 */

export const MSG_STATUS_SENT = "sent";
export const MSG_STATUS_DELETED = "deleted";
export const MSG_STATUS_DELIVERED = "delivered";
export const MSG_STATUS_WAITING = "waiting";
export const MSG_STATUS_EXPIRED = "expired";
export const MSG_STATUS_READ = "read";

export const MSG_TYPE_ONTI = "onti";
export const MSG_TYPE_MENTOR = "mentor";
export const MSG_TYPE_EVENT = "event";
export const MSG_TYPE_ACHIEVEMENT = "achievement";
export const MSG_TYPE_ORG = "org_info";

export const CHANNEL_WS = "ws";
export const CHANNEL_EMAIL = "email";
export const CHANNEL_TELEGRAM = "telegram";

export const ALL_MSG_TYPES = Object.freeze([
  MSG_TYPE_ONTI,
  MSG_TYPE_MENTOR,
  MSG_TYPE_EVENT,
  MSG_TYPE_ACHIEVEMENT,
  MSG_TYPE_ORG,
]);
