/**
 * Участник приглашает наставника,
 * стать его наставником
 * @param {string} code
 * @param {number} talent_id
 * @returns {string} url
 */
export const userInviteMentorLink = (code, talent_id) => {
  if (!code || !talent_id) return "";
  return `${window.location.origin}/invite/m/${talent_id}/${code}`;
};

/**
 * Участник А приглашает другого участника Б
 * @param {object} data
 * @param {string} data.code - код приглашения
 * @param {number} data.talent_id - id участника А
 * @returns {string} url
 */
export const userInviteFriendLink = ({ code, talent_id }) => {
  return `${window.location.origin}/invite/f/${code}/${talent_id}`;
};

/**
 * Наставник приглашает ученика
 * присоединиться к нему в качестве
 * подопечного
 * @param {object} data
 * @param {string} data.code - код приглашения
 * @param {number} data.org - id организации
 * @param {number} data.mentor - id наставника
 * @param {number?} data.profile - id профиля
 * @returns {string} url
 */
export const mentorInviteUserLink = ({ code, org, mentor, profile }) => {
  const base = `${window.location.origin}/invite/u/${code}/${mentor}/${org}`;
  if (profile) {
    return base + `?profile_id=${profile}`;
  }
  return base;
};

/**
 * Наставник А приглашает другого наставника Б
 * присоединиться к его организации
 * @param {object} data
 * @param {string} data.code - код приглашения
 * @param {number} data.org - id организации
 * @returns {string} url
 */
export const mentorInviteMentorLink = ({ code, org }) => {
  return `${window.location.origin}/invite/a/${code}/${org}`;
};

export const createOrganizationInviteLink = ({ code, org }) => {
  return `${window.location.origin}/invite/o/${code}/${org}`;
};
