export const PROFILE_LABELS = {
  profile: {
    name: "Профиль",
    select: "Выбрать профиль",
    selected: "Профиль выбран",
  },
  special: {
    name: "Спецпроект",
    select: "Выбрать спецпроект",
    selected: "Спецпроект выбран",
  },
  sphere: {
    name: "Сфера",
    select: "Выбрать сферу",
    selected: "Сфера выбрана",
  },
};
