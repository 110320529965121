import dayjs from "dayjs";
import "dayjs/locale/ru";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import minMax from "dayjs/plugin/minMax";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
// const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
// const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.locale("ru");
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(minMax);
dayjs.extend(isBetween);
dayjs.extend(duration);
dayjs.extend(utc);
// dayjs.extend(isSameOrBefore);
// dayjs.extend(isSameOrAfter);

export default dayjs;
