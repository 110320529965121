<template>
  <div>Authorize</div>
</template>

<script>
import { clientApiRequest } from "@/services/api";
export default {
  name: "Authorize",
  metaInfo() {
    return {
      title: "Авторизация ...",
    };
  },
  async beforeRouteEnter(to, from, next) {
    const { code } = to.query;
    let redirect = to.query.next || "/";
    const params = new URLSearchParams(window.location.search);
    let url = window.location.origin + window.location.pathname;
    params.delete("code");
    if ([...params.values()].length) {
      url += `?${params}`;
    }
    try {
      // если нажать в браузере кнопку назад,
      // после авторизации, то выскочит ошибка, потому что, такой код уже использован
      // надо смотреть, если мы авторизовали этот код, то просто перенаправить
      // дальше без запроса.
      const savedCode = localStorage.getItem("v_code");
      if (savedCode !== code) {
        // Аторизуем юзера в таланте
        await clientApiRequest({
          method: "POST",
          url: "/authorize",
          data: {
            code,
            redirect_uri: url,
          },
        });
        localStorage.setItem("v_code", code);
      }
      next(redirect);
    } catch (error) {
      next("/?error=auth_error");
    }
  },
};
</script>
