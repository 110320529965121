import { MENTOR_ROLE } from "@/constants";
import store from "@/store";

export const authorizeMiddleware = async (to, from, next) => {
  const storedUser = store.state.user.user;

  if (storedUser?.is_adult_confirm_intruder && to.name !== "consent_intruder") {
    next({
      name: "consent_intruder",
      query: {
        next: to.fullPath,
      },
    });
    return;
  }
  if (to.meta.auth && !storedUser) {
    try {
      store.commit("ui/SET_STATE", { key: "loading", value: true });
      const user = await store.dispatch("user/getMe");
      store.commit("ui/SET_STATE", { key: "loading", value: false });
      if (user?.is_adult_confirm_intruder && to.name !== "consent_intruder") {
        next({
          name: "consent_intruder",
          query: {
            next: to.fullPath,
          },
        });
        return;
      }
      if (user?.role === MENTOR_ROLE) {
        return next();
      }
      // Если юзер еще не создавал participant
      if (!user?.role) {
        return next({
          name: "registration",
          query: {
            next: to.fullPath,
          },
        });
      }
      next();
    } catch (error) {
      store.commit("ui/SET_STATE", { key: "loading", value: false });
      // Если 404 то юзер не найден, но авторизован в таланте
      // Нужно отправить его на форму регистрации
      if (error.status === 404) {
        return next({
          name: "registration",
          query: {
            next: to.fullPath,
          },
        });
      }
      if (to.name === "index") {
        return next();
      }
      return next({
        path: "/",
        query: {
          next: to.fullPath,
          error: "auth",
        },
      });
    }
  } else {
    // Роут не закрыт авторизацией
    next();
  }
};

export const silentAuthMiddleware = async (to, from, next) => {
  if (
    to.meta.silentAuth &&
    !store.state.user.guest &&
    !store.getters["user/user"]
  ) {
    try {
      const user = await store.dispatch("user/getMe");
      if (user?.is_adult_confirm_intruder && to.name !== "consent_intruder") {
        next({
          name: "consent_intruder",
          query: {
            next: to.fullPath,
          },
        });
        return;
      }
    } catch (error) {
      console.log("error", error.status);
      // @FIXME if not 404 then redirect to error
    }
  }
  next();
};
