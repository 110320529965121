<template>
  <div class="error-modal">
    <BaseModalContent
      :name="name"
      :dynamic="true"
      @close="$emit('close')">
      <div class="mb-m">
        <h2 class="text-bold text-size-h2">
          <span v-html="title"></span><span v-if="status"> ({{ status }})</span>
        </h2>
      </div>
      <div
        v-if="content"
        class="mb-s"
        v-html="content"></div>
      <div
        v-if="message"
        class="mb-l error-message">
        <p>{{ message }}</p>
        <div v-if="false">
          <span class="method">{{ url }}</span>
        </div>
      </div>

      <div class="mt-l btns">
        <BaseButton
          v-if="hasSupportBtn"
          @click.prevent="sendReport"
          >Отправить отчет</BaseButton
        >
        <BaseButton
          :theme="hasSupportBtn ? 'primary-border' : 'primary'"
          @click="$emit('close')"
          >Закрыть</BaseButton
        >
      </div>
    </BaseModalContent>
  </div>
</template>

<script>
export default {
  name: "ErrorModal",
  props: {
    title: {
      type: String,
      default: "Произошла ошибка",
    },
    content: {
      type: String,
    },
    message: {
      type: String,
    },
    status: {
      type: [String, Number],
    },
    url: {
      type: String,
      default: "",
    },
    report: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: "error-modal",
    };
  },
  computed: {
    hasSupportBtn() {
      if (this.report) return true;
      if (!this.message) return;
      const lc = this.message.toLowerCase();
      return lc.includes("сообщите нам") || lc.includes("службу поддержки");
    },
  },
  methods: {
    sendReport() {
      const { status, url, message } = this;
      const err = [status, url, message].filter(Boolean).join("--");
      this.$router.push({ name: "support", query: { err } });
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.method {
  font-size: 14px;
  color: #515151;
}

.btns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}
</style>
