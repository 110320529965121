<template>
  <Component
    :is="tag"
    :disabled="disabled || loading"
    :type="btnType"
    :to="to"
    :class="classNames"
    @click="handleClick">
    <span
      v-if="loading"
      :class="[rootClass + '__loader']">
      <slot name="loading">
        <BaseSpinner
          :class="[rootClass + '__spinner']"
          :size="size === 'sm' ? '16px' : '20px'" />
      </slot>
    </span>
    <span :class="[rootClass + '__inner']">
      <slot></slot>
    </span>
  </Component>
</template>
<script>
const BTN_CLASS_NAME = "base-btn";
export default {
  name: "AppButton",
  props: {
    to: [String, Object],
    tag: {
      type: String,
      default: "button",
    },
    type: {
      type: String,
      default: "button",
    },
    loading: Boolean,
    disabled: Boolean,
    /**
     * Тема для кнопки
     * @values primary, secondary, subtle, text, primary-border
     */
    theme: {
      type: String,
      default: "primary",
    },
    block: {
      type: Boolean,
      default: false,
    },
    size: {
      // lg, md, sm
      type: String,
      default: "md",
    },
    xSpace: {
      // lg, md, sm
      type: String,
      default: "",
    },
  },

  computed: {
    btnType() {
      const { type, tag } = this;
      return tag === "button" ? type : null;
    },
    rootClass() {
      return BTN_CLASS_NAME;
    },
    classNames() {
      const { disabled, loading, block, theme, size, xSpace } = this;
      const result = [
        BTN_CLASS_NAME,
        `${BTN_CLASS_NAME}--theme-${theme}`,
        `${BTN_CLASS_NAME}--size-${size}`,
        {
          "is-disabled": disabled,
          "is-loading": loading,
          [`${BTN_CLASS_NAME}--block`]: block,
        },
      ];
      if (xSpace) {
        result.push(`${BTN_CLASS_NAME}--xspace-${xSpace}`);
      }
      return result;
    },
  },

  methods: {
    handleClick(event) {
      const { disabled, loading } = this;
      if (disabled || loading) {
        event.preventDefault();
      } else {
        this.$emit("click", event);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@btn-height: 40px;
@btn-xspase: 20px;
@btn-radius: 3px;
@danger-color: darken(@error-color, 10%);

.base-btn {
  height: @btn-height;
  display: inline-block;
  line-height: @btn-height - 2px; // border-compensation
  padding: 0 @btn-xspase;
  border-width: 1px;
  border-style: solid;
  border-color: @base-color;
  border-radius: @btn-radius;
  color: @base-color;
  font-family: inherit;
  outline: none;
  background: transparent;
  transition: background 0.3s, border 0.3s, color 0.3s;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  user-select: none;
  outline-offset: 0;
  cursor: pointer;
  position: relative;
  min-width: 100px;
  text-align: center;

  &__inner {
    opacity: 1;
    transition: opacity 0.3s;
  }

  &.is-loading &__inner {
    opacity: 0;
  }

  &__spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:focus {
    outline: @black-menu solid 2px;
  }

  &:active {
    transform: translateY(1px);
    box-shadow: none;
  }

  // &:hover {
  //   border-color: darken(@primary-color, 10%);
  //   background-color: darken(@primary-color, 10%);
  // }

  &--theme-reg {
    // height: @reg-input-height;
    // line-height: @reg-input-height - 2px; // border-compensation
    background: transparent;
    color: @lp-base-color;
    border-color: @lp-base-color;
    font-weight: bold;

    &:hover {
      background-color: @lp-base-color;
      color: @bg-dark;
    }
  }

  &--theme-secondary {
    background-color: @secondary-color;
    border-color: @secondary-color;
    color: #fff;
    &:hover {
      background-color: darken(@secondary-color, 10%);
      border-color: darken(@secondary-color, 10%);
    }
  }

  &--theme-blue-light {
    background-color: @principal-blue-light;
    border-color: @principal-blue-light;
    color: @principal-blue;
    &:hover {
      background-color: darken(@principal-blue-light, 10%);
      border-color: darken(@principal-blue-light, 10%);
    }
  }

  &--theme-primary {
    background-color: @primary-color;
    border-color: @primary-color;
    color: #fff;

    &:hover {
      background-color: darken(@primary-color, 10%);
      border-color: darken(@primary-color, 10%);
    }

    &:focus {
      outline-color: fade(@primary-color, 30%);
    }

    &.is-disabled {
      background-color: lighten(@disabled-color, 25%);
      border-color: lighten(@disabled-color, 25%);
      color: darken(@disabled-color, 10%);
    }
  }

  &--theme-primary-border {
    background-color: transparent;
    border-color: @primary-color;
    color: @primary-color;

    &:hover {
      border-color: darken(@primary-color, 10%);
      background-color: fade(@primary-color, 5%);
      color: darken(@primary-color, 10%);
    }
    &:focus {
      outline-color: fade(@primary-color, 20%);
    }

    &.is-disabled {
      background-color: lighten(@disabled-color, 25%);
      border-color: lighten(@disabled-color, 25%);
      color: darken(@disabled-color, 10%);
    }
  }

  &--theme-secondary.is-disabled {
    background-color: #c0c0c0;
    border-color: #c0c0c0;
  }

  &--theme-primary &__spinner /deep/ .base-spinner__rotator {
    border-color: transparent white white !important;
  }

  &--theme-accent {
    background-color: @accent-blue;
    color: #fff;
    border-color: @accent-blue;

    &:hover {
      border-color: darken(@accent-blue, 15%);
      background-color: darken(@accent-blue, 15%);
    }
  }
  &--theme-white {
    background-color: #fff;
    border-color: transparent;
    color: @link-color;

    &:hover {
      background-color: fade(#fff, 30%);
      border-color: transparent;
      color: #fff;
    }

    &.is-disabled {
      background-color: lighten(@disabled-color, 25%);
      border-color: lighten(@disabled-color, 25%);
      color: darken(@disabled-color, 10%);
    }
  }

  &--theme-success {
    background-color: @success-color;
    border-color: @success-color;
    color: #fff;

    &:hover {
      background-color: darken(@success-color, 10%);
      border-color: darken(@success-color, 10%);
      color: #fff;
    }

    &.is-disabled {
      background-color: lighten(@disabled-color, 25%);
      border-color: lighten(@disabled-color, 25%);
      color: darken(@disabled-color, 10%);
    }
  }

  &--block {
    display: block;
    width: 100%;
    text-align: center;
  }

  &--size-lg {
    height: @btn-lg-height;
    line-height: @btn-lg-height - 2px; // border-compensation
    padding: 0 @btn-lg-xspase;
    .fz(14);
    text-transform: uppercase;
    letter-spacing: 0.02em;
  }
  &--size-xlg {
    height: 66px;
    line-height: 66px - 2px; // border-compensation
    padding: 0 @btn-lg-xspase;
    .fz(18);
    text-transform: uppercase;
    letter-spacing: 0.02em;
    border-radius: 8px;
  }

  &--size-md {
    height: @btn-height;
    line-height: @btn-height - 2px; // border-compensation
    padding: 0 @btn-xspase;
    .fz(12);
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }

  &--size-sm {
    height: @btn-sm-height;
    line-height: @btn-sm-height - 2px;
    .fz(12);
  }

  &--size-xs {
    height: @btn-sm-height - 4px;
    line-height: @btn-sm-height - 6px;
    .fz(12);
    min-width: 1px;
  }

  &--xspace-sm {
    padding-left: @btn-xspase - 4;
    padding-right: @btn-xspase - 4;
  }
  &--xspace-lg {
    padding-left: @btn-lg-xspase;
    padding-right: @btn-lg-xspase;

    @media screen and (max-width: @screen-xs) {
      padding-left: @btn-xspase;
      padding-right: @btn-xspase;
    }
  }
  &--xspace-md {
    padding-left: @btn-xspase;
    padding-right: @btn-xspase;
  }

  &.is-loading {
    cursor: default;

    &:active {
      transform: none;
    }
  }

  &.is-disabled {
    pointer-events: none;
    cursor: not-allowed;

    &:active {
      transform: none;
    }
  }
}
</style>
