<template>
  <span
    :style="containerStyle"
    :class="classNames"
    role="presentation"
    title="Подождите...">
    <span
      :style="spinnerStyle"
      class="base-spinner__rotator">
      <svg
        v-if="brand"
        viewBox="0 0 21 21"
        class="base-spinner__brand"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.08423 5.12946C4.14003 5.11086 4.17722 5.07366 4.23301 5.05507C3.67509 4.38556 3.65649 4.088 3.74948 3.95782C4.08423 3.51148 5.06989 3.04655 6.52049 3.04655C7.33877 3.04655 8.17566 3.19533 8.97534 3.49289C10.2586 3.95782 11.0768 4.31117 11.6162 4.53434C12.1555 4.75751 12.4717 4.88769 12.8064 4.96208C13.0668 5.01787 13.3085 5.03647 13.5131 5.03647C13.6433 5.03647 13.7735 5.01787 13.8851 4.99927C13.8107 4.85049 13.7549 4.70172 13.6805 4.55294C13.5689 4.32977 13.4573 4.1066 13.3271 3.88343C13.2341 3.88343 13.1412 3.86483 13.0296 3.84624C12.8064 3.79044 12.5274 3.67886 12.0439 3.47429C11.486 3.25112 10.6677 2.89777 9.34729 2.41424C8.43602 2.07949 7.46896 1.91211 6.52049 1.91211C4.90252 1.91211 3.48912 2.43284 2.8754 3.26972C2.28029 4.05081 2.72662 4.94348 3.22875 5.61299C3.50771 5.44561 3.78668 5.27823 4.08423 5.12946Z" />
        <path
          d="M15.5031 3.67908C15.5217 3.73487 15.5403 3.77207 15.5775 3.82786C15.875 3.5675 16.247 3.30713 16.4887 3.30713C16.5445 3.30713 16.5817 3.32573 16.6189 3.36292C17.3628 3.93944 17.9579 6.18973 17.0838 8.70037C16.6375 10.0022 16.3027 10.8205 16.0796 11.3784C15.8564 11.9363 15.7262 12.2525 15.6518 12.5872C15.5589 13.0336 15.5589 13.3869 15.596 13.6845C15.7448 13.6101 15.8936 13.5543 16.0424 13.4613C16.2842 13.3497 16.4887 13.2195 16.6933 13.0894C16.6933 12.9964 16.7119 12.9034 16.7305 12.7918C16.7677 12.5686 16.8979 12.2711 17.0838 11.7875C17.307 11.2296 17.6418 10.3741 18.1253 9.03512C19.1296 6.13393 18.4786 3.34433 17.2884 2.41446C17.0466 2.22848 16.7677 2.1355 16.4701 2.1355C15.9494 2.1355 15.4473 2.43306 15.0195 2.76781C15.2055 3.08396 15.3729 3.36293 15.5031 3.67908Z" />
        <path
          d="M16.916 15.358C16.8602 15.3766 16.823 15.4138 16.7672 15.4324C17.3251 16.1019 17.3437 16.3995 17.2508 16.5297C16.916 16.976 15.9303 17.441 14.4798 17.441C13.6801 17.441 12.8246 17.2922 12.0249 16.9946C10.7417 16.5297 9.94199 16.1949 9.40267 15.9532C8.86334 15.73 8.54719 15.5812 8.21244 15.5254C7.95207 15.4696 7.7289 15.451 7.52433 15.451C7.37555 15.451 7.24537 15.4696 7.13379 15.4882C7.18958 15.637 7.26397 15.7858 7.33836 15.9346C7.44994 16.1577 7.56153 16.3809 7.71031 16.6041C7.80329 16.6041 7.89628 16.6227 7.98927 16.6413C8.21244 16.6971 8.50999 16.8086 8.97493 17.0132C9.53285 17.2364 10.3511 17.5897 11.6715 18.0733C12.5828 18.408 13.5499 18.5754 14.4983 18.5754C15.9117 18.5754 17.5297 18.0547 18.162 17.2178C18.7571 16.4367 18.3108 15.544 17.8087 14.8745C17.5111 15.0605 17.2136 15.2093 16.916 15.358Z" />
        <path
          d="M5.49749 16.8274C5.47889 16.7716 5.4417 16.7158 5.4231 16.66C5.10695 16.9576 4.77219 17.2366 4.54903 17.2366C4.53043 17.2366 4.47464 17.2366 4.36305 17.1622C3.61916 16.5856 3.02404 14.3354 3.89812 11.8247C4.36305 10.5043 4.6978 9.66742 4.92097 9.1281C5.14414 8.57017 5.27432 8.25402 5.34871 7.91927C5.4417 7.47293 5.4417 7.11958 5.40451 6.82202C5.25573 6.89641 5.10695 6.9522 4.95817 7.02659C4.7164 7.13818 4.51183 7.26836 4.30726 7.39854C4.30726 7.49153 4.28866 7.58451 4.27007 7.6961C4.23287 7.91927 4.10269 8.21682 3.91671 8.70036C3.69355 9.25828 3.3402 10.1138 2.87526 11.4528C1.85241 14.3354 2.50331 17.125 3.69355 18.0548C3.95391 18.2594 4.25147 18.371 4.54903 18.371C5.06975 18.371 5.51609 18.0734 5.96243 17.6829C5.79505 17.4039 5.62767 17.125 5.49749 16.8274Z" />
        <path
          d="M2.42855 11.2671C2.44714 11.2113 2.46574 11.1555 2.48434 11.0812C1.64746 11.0254 1.42429 10.9324 1.38709 10.672C1.25691 9.72355 2.39135 7.71503 4.75322 6.56199C5.98064 5.96688 6.79893 5.61353 7.33825 5.39036C7.87758 5.16719 8.19373 5.01841 8.47269 4.83244C8.84464 4.57207 9.105 4.33031 9.27238 4.08854C9.1236 4.03275 8.97482 3.97696 8.80745 3.92117C8.58428 3.82818 8.34251 3.77239 8.10075 3.698C8.02636 3.75379 7.95197 3.80958 7.85898 3.88397C7.67301 4.01415 7.37545 4.12574 6.91051 4.33031C6.37119 4.57207 5.53431 4.92542 4.26969 5.52054C1.55447 6.87815 0.0852761 9.333 0.289847 10.8394C0.420029 11.918 1.3313 12.1412 2.14959 12.2156C2.22397 11.8994 2.31696 11.5833 2.42855 11.2671Z" />
        <path
          d="M9.55434 2.44209C9.61013 2.46069 9.66592 2.47929 9.72171 2.49788C9.7961 1.6424 9.98208 1.40064 10.1309 1.38204C10.1681 1.38204 10.2238 1.38204 10.261 1.38204C11.3583 1.38204 13.1436 2.70246 14.1293 4.82256C14.7058 6.08718 15.0592 6.92406 15.2823 7.46338C15.5055 8.00271 15.6357 8.33746 15.8217 8.63502C16.0634 9.02556 16.3052 9.26733 16.5469 9.4533C16.6027 9.30452 16.6585 9.15574 16.7143 8.98837C16.8073 8.728 16.8631 8.48624 16.9375 8.24447C16.8817 8.17008 16.8259 8.09569 16.7701 8.00271C16.6399 7.79814 16.5284 7.51918 16.3238 7.03564C16.1006 6.47772 15.7473 5.62224 15.1521 4.33902C13.9247 1.661 11.7116 0.229004 10.2796 0.229004C10.1866 0.229004 10.0937 0.229004 10.0007 0.247601C9.05221 0.377783 8.73605 1.30765 8.64307 2.14453C8.94062 2.23752 9.25678 2.33051 9.55434 2.44209Z" />
        <path
          d="M20.7096 9.64895C20.5794 8.66329 19.6682 8.34714 18.8499 8.25415C18.7755 8.57031 18.6825 8.90506 18.5709 9.22121C18.5523 9.27701 18.5337 9.3328 18.5151 9.36999C19.352 9.44438 19.5752 9.63036 19.5938 9.79773C19.724 10.7462 18.5895 12.7547 16.2277 13.9078C15.0002 14.5029 14.1819 14.8562 13.6426 15.0794C13.1033 15.3026 12.7871 15.4513 12.5082 15.6373C12.1362 15.8977 11.8759 16.1394 11.7085 16.3812C11.8573 16.437 12.0061 16.4928 12.1734 16.5486C12.3966 16.6416 12.6384 16.6974 12.8801 16.7718C12.9545 16.716 13.0289 16.6602 13.1219 16.5858C13.3079 16.4556 13.6054 16.344 14.0704 16.1394C14.6097 15.8977 15.4466 15.5443 16.7112 14.9492C19.445 13.6288 20.9142 11.1739 20.7096 9.64895Z" />
        <path
          d="M11.5042 18.5204C11.4484 18.5018 11.3926 18.4833 11.3368 18.4647C11.2624 19.3201 11.0765 19.5619 10.9277 19.5805C10.8905 19.5805 10.8347 19.5805 10.7975 19.5805C9.70026 19.5805 7.91492 18.2601 6.92926 16.14C6.33414 14.8754 5.99939 14.0385 5.77622 13.4992C5.55305 12.9412 5.42287 12.6251 5.23689 12.3275C4.99513 11.937 4.73476 11.6952 4.5116 11.5278C4.4558 11.6766 4.40001 11.8254 4.34422 11.9928C4.25123 12.2531 4.19544 12.4949 4.13965 12.7367C4.19544 12.8111 4.25123 12.8854 4.30703 12.9784C4.43721 13.183 4.54879 13.462 4.75336 13.9641C4.97653 14.522 5.32988 15.3775 5.925 16.6607C7.17102 19.3201 9.36551 20.7521 10.8161 20.7707C10.9091 20.7707 11.0021 20.7707 11.0951 20.7521C12.0435 20.622 12.3597 19.6921 12.4527 18.8552C12.1179 18.725 11.8018 18.632 11.5042 18.5204Z" />
      </svg>
    </span>
  </span>
</template>

<script>
export default {
  name: "BaseSpinner",
  props: {
    brand: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "24px",
    },
    accent: {
      type: String,
      default: "#32c1b9",
    },
    // "normal", "fast", "slow"
    speed: {
      type: String,
      default: "normal",
    },
    borderWidth: {
      type: String,
    },
    valign: {
      type: String,
    },
    theme: {
      type: String,
    },
  },
  computed: {
    classNames() {
      const baseClass = "base-spinner";
      const { speed, valign, brand, theme } = this;
      const result = [baseClass, `${baseClass}--speed-${speed}`];
      if (valign) {
        result.push(`${baseClass}--valign-${valign}`);
      }
      brand && result.push(`${baseClass}--brand`);
      theme && result.push(`${baseClass}--theme-${theme}`);
      return result;
    },
    containerStyle() {
      const { size } = this;
      return {
        width: size,
        height: size,
      };
    },
    spinnerStyle() {
      const { size, accent, borderWidth, theme } = this;
      const width = parseInt(size, 10);

      const result = {
        borderWidth: borderWidth || Math.ceil(width * 0.1) + "px",
      };

      if (!theme) {
        result.borderColor = `currentColor ${accent} ${accent} ${accent}`;
      }

      return result;
    },
  },
};
</script>

<style lang="less">
.base-spinner {
  display: inline-block;
  line-height: 0;
  width: 24px;
  height: 24px;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  will-change: transfrom;
  transform: translateZ(0);
  line-height: 1;

  &--valign-middle {
    vertical-align: middle;
  }
  &--valign-top {
    vertical-align: top;
  }
  &--valign-baseline {
    vertical-align: baseline;
  }

  &__rotator {
    display: block;
    border-style: solid;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    animation: spinnerRotation 1s linear infinite both;
    transform-origin: center center;
    font-size: 10px;
    line-height: 1;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &--speed-normal &__rotator {
    animation-duration: 1s;
  }

  &--speed-fast &__rotator {
    animation-duration: 0.5s;
  }

  &--speed-slow &__rotator {
    animation-duration: 2s;
  }

  &--brand &__rotator {
    border: 0;
    animation-duration: 2s;
  }

  &__brand {
    fill: currentColor;

    path {
      // animation: brandBlink 4s linear infinite both;
      // opacity: 0.2;

      // &:nth-child(2) {
      //   // animation-delay: 0.1s;
      // }
      // &:nth-child(3) {
      //   // animation-delay: 0.15s;
      // }
      // &:nth-child(4) {
      //   // animation-delay: 0.2s;
      // }
      &:nth-child(5) {
        animation-delay: 0.25s;
      }
      &:nth-child(6) {
        animation-delay: 0.3s;
      }
      &:nth-child(7) {
        animation-delay: 0.35s;
      }
      &:nth-child(8) {
        animation-delay: 0.4s;
      }
    }
  }

  &--theme-reg &__rotator {
    border-color: fade(#fff, 10%);
    border-top-color: @lp-outline-color;
  }

  &--theme-primary &__rotator {
    border-color: transparent;
    border-top-color: @primary-color;
  }

  &--theme-white &__rotator {
    border-color: fade(#fff, 30%);
    border-top-color: #fff;
  }
}
@keyframes brandBlink {
  form {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0.2;
  }
}
@keyframes spinnerRotation {
  to {
    transform: rotateZ(1turn);
  }
}
</style>
