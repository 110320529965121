<template>
  <div
    class="role-card"
    :class="[`role-card--theme-${role}`]">
    <div class="role-card__head">
      <div class="role-card__label">
        <div class="role-card__corner"></div>
        <span class="role-card__label-inner"></span>
        <slot name="label"></slot>
      </div>
      <span class="role-card__label-info">
        <slot name="label_info"></slot>
      </span>
      <div class="role-card__label-img">
        <slot name="label_img"></slot>
      </div>
    </div>
    <div class="role-card__body">
      <div class="role-card__body__inner">
        <slot name="body"></slot>
      </div>
      <router-link
        v-if="showBtn"
        class="role-card__btn"
        :to="{ name: `registration-${role}`, query: $route.query }"
        >Выбрать роль <span class="role-card__chevron"><span></span></span
      ></router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoleCard",
  props: {
    role: {
      type: String,
      validator(value) {
        return ["student", "school", "mentor"].includes(value);
      },
    },
    showBtn: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less">
@rolecard-border-color: @lp-outline-color;
@rolecard-bg-color: lighten(@bg-dark, 2%);
@rolecard-radius: 5px;
@rolecard-label-height: 58px;
@role-cord-inner-offset: 35px;
@role-cord-inner-offset-mobile: 20px;
@rolecard-student-color: #f1247f;
@rolecard-mentor-color: #00b2ff;
@rolecard-school-color: #0075ff;
@rolecard-label-color: @periwinkle;

.role-card {
  display: flex;
  flex-direction: column;

  .reg-warning {
    // font-size: 0.85em;
    line-height: 1.43;
    margin: 20px -@role-cord-inner-offset -@role-cord-inner-offset;
    padding: @role-cord-inner-offset / 2 @role-cord-inner-offset;
    background-color: fade(#000, 20%);
  }

  .hr {
    margin: 40px -@role-cord-inner-offset 40px;
    background: transparent;
    border: 0;
    height: 2px;
    background: repeating-linear-gradient(
      90deg,
      @lp-outline-color,
      @lp-outline-color 2px,
      transparent 2px,
      transparent 8px
    );
  }

  .link {
    color: darken(@lp-base-color, 14%);

    &:hover {
      color: lighten(@lp-base-color, 5%);
    }
  }

  &__head {
    flex-grow: 0;
    display: flex;
    align-items: center;
  }

  &__body {
    border: 1px solid @rolecard-border-color;
    border-bottom-left-radius: @rolecard-radius;
    border-bottom-right-radius: @rolecard-radius;
    background-color: @rolecard-bg-color;
    position: relative;
    z-index: 1;
    flex-grow: 1;
    line-height: 1.32;
    display: flex;
    flex-direction: column;

    &__inner {
      padding: @role-cord-inner-offset;
      flex-grow: 1;
    }

    .role-options-list {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        position: relative;
        padding-left: 2em;
        margin-bottom: 2em;

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          position: absolute;
          display: inline-block;
          vertical-align: middle;
          top: 0.25em;
          left: 0;
          width: 0.8em;
          height: 0.8em;
          border: 0.2em solid #fff;
          content: "";
          border-radius: 50%;
        }
      }
    }
  }

  &__corner {
    width: 60px;
    top: -2px;
    bottom: -6px;
    overflow: hidden;
    position: absolute;
    right: @rolecard-label-height * -1 + 2px;
    display: block;

    &::before {
      width: 100px;
      top: 0px;
      bottom: 0px;
      right: 0;
      border: 1px solid @rolecard-border-color;
      content: "";
      display: block;
      position: absolute;
      transform: skewX(30deg) translateX(-40px);
      border-radius: @rolecard-radius;
      border-bottom: 0;
      background-color: @rolecard-bg-color;
    }
  }

  &__label {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: @rolecard-label-height;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid @rolecard-border-color;
    position: relative;
    padding-left: @role-cord-inner-offset;
    padding-right: @role-cord-inner-offset;
    background-color: @rolecard-bg-color;
    border-right: 0;
    border-bottom: 0;
    z-index: 0;
    color: @rolecard-label-color;
    font-weight: bold;
    font-size: 22px;

    &-info {
      margin-left: 50px;
      display: inline-block;
      font-size: 14px;
      color: @lp-link-color;
    }

    &-img {
      margin-left: auto;
    }
  }

  &__btn {
    display: block;
    height: @rolecard-label-height;
    text-align: center;
    line-height: @rolecard-label-height;
    color: @lp-base-color;
    text-decoration: none;
    border: 0;
    outline: none;
    border-top: 1px solid @rolecard-border-color;
    transition: color 0.3s, background 0.3s;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    flex-grow: 0;
    border-bottom-right-radius: @rolecard-radius;
    border-bottom-left-radius: @rolecard-radius;

    &:hover,
    &:focus {
      background-color: fade(#fff, 2%);
    }
  }

  &__chevron {
    display: inline-block;
    width: 32px;
    position: relative;
    margin-left: 10px;

    span,
    &::before,
    &::after {
      width: 8px;
      height: 8px;
      border: 2px solid currentColor;
      border-left: 0;
      border-bottom: 0;
      transform-origin: center;
      transform: rotateZ(45deg) translate(0, 0);
      display: block;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      display: block;
    }

    &::before {
      left: 10px;
    }
    &::after {
      left: 20px;
    }
  }

  &__btn:hover &__chevron {
    span,
    &::before,
    &::after {
      animation: chevronShift 1.2s ease-in-out infinite both;
    }

    &::before {
      animation-delay: 50ms;
    }

    &::after {
      animation-delay: 100ms;
    }
  }

  &--theme-student &__btn:hover {
    color: @rolecard-student-color;
  }
  &--theme-student &__body ul li::before {
    border-color: @rolecard-student-color;
  }
  &--theme-mentor &__btn:hover {
    color: @rolecard-mentor-color;
  }
  &--theme-mentor &__body ul li::before {
    border-color: @rolecard-mentor-color;
  }
  &--theme-school &__btn:hover {
    color: @rolecard-school-color;
  }
  &--theme-school &__body ul li::before {
    border-color: @rolecard-school-color;
  }

  // &--theme-school .reg-warning b {
  //   color: @rolecard-school-color;
  // }

  @media screen and (max-width: @screen-md) {
    &__label {
      padding-left: @role-cord-inner-offset-mobile;
      padding-right: @role-cord-inner-offset-mobile;

      &-img {
        display: none;
      }
    }
    &__body__inner {
      padding: @role-cord-inner-offset-mobile;
    }
  }

  @media screen and (max-width: @screen-sm) {
    &__head {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    &__label {
      font-size: 18px;
      &-info {
        margin-left: 0;
        margin-bottom: 20px;
      }
    }
  }

  @media screen and (max-width: @screen-xs) {
    &__head {
      overflow: hidden;
    }
    &__label {
      margin-right: 35px;
    }
  }
}

@keyframes chevronShift {
  0% {
    transform: rotateZ(45deg) translate(0, 0) scale(1);
    opacity: 1;
  }

  50% {
    transform: rotateZ(45deg) translate(8px, -8px) scale(1.1);
    opacity: 0.6;
  }

  100% {
    transform: rotateZ(45deg) translate(0, 0) scale(1);
    opacity: 1;
  }
}
</style>
