<template>
  <span
    :style="iconStyles"
    :class="classNames"
    :title="title"
    role="image"
    v-on="$listeners">
    <svg><use :xlink:href="iconPath"></use></svg>
  </span>
</template>

<script>
export default {
  name: "AppIcon",
  props: {
    glyph: {
      type: String,
      required: true,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    color: {
      type: String,
    },
    baseClass: {
      type: String,
      default: "base-icon",
    },
    prefix: {
      type: String,
      default: "icon-",
    },
    title: {
      type: String,
      default: null,
    },
    valign: {
      type: String,
      default: "middle",
    },
  },
  computed: {
    iconStyles() {
      const { width, height, color, valign } = this;
      const defaultHeight = width && !height ? width : height;
      const result = {
        width: width ? width : null,
        height: defaultHeight || null,
        color: color ? color : null,
        verticalAlign: valign,
      };
      return result;
    },
    classNames() {
      const { baseClass, glyph } = this;
      const result = [baseClass];
      if (!glyph) return result;
      return [baseClass, `${baseClass}--${glyph}`];
    },
    iconPath() {
      const { prefix, glyph } = this;
      const result = `#${prefix}${glyph}`;
      return result;
    },
  },
};
</script>
<style lang="less">
// !!!Warning
// Do not use `scoped` at this styles
.base-icon {
  fill: currentColor;
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;

  svg {
    fill: inherit !important;
    width: 100%;
    height: 100%;
    display: block;
  }
}
</style>
