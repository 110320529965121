/**
 * @typdef {object} EventSlice
 * @property {number} id
 * @property {string} title
 * @property {string} region_with_type
 * @property {string} format
 * @property {string} start
 * @property {string} end
 * @property {number?} max_users
 * @property {string?} address
 * @property {number[]?} achievement_roles
 * @property {date?} updated_at
 * @property {string?} url
 * @property {string?} team_assignment_method
 * @property {boolean} team_create_notification
 * @property {string} teams_involved
 * @property {number} type
 * @property {string} country
 * @property {string} city
 * @property {number?} organization_id
 */

/**
 *
 * @param {*} event
 * @returns {EventSlice}
 */
export const eventSlice = (event) => {
  return {
    id: event.id,
    title: event.title,
    region_with_type: event.region_with_type,
    federal_district: event.federal_district,
    format: event.format,
    start: event.start,
    end: event.end,
    max_users: event.max_users,
    address: event.address,
    achievement_roles: event.achievement_roles,
    updated_at: event.updated_at,
    url: event.url,
    team_assignment_method: event.team_assignment_method,
    team_create_notification: event.team_create_notification,
    teams_involved: event.teams_involved,
    type: event.type,
    country: event.country,
    city: event.city,
    organization_id: event.organization?.id,
    description: event.description,
  };
};
