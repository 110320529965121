<template>
  <div
    class="base-image"
    :class="roootClasses">
    <slot name="placeholder">
      <div
        v-if="pending || loadError"
        class="base-image__placeholder"></div>
    </slot>
    <div
      class="base-image__cover"
      :style="imageStyle"></div>
    <div class="base-image__body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { loadImage } from "@/utils";
export default {
  name: "BaseImage",
  props: {
    src: {
      type: String,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    aspectRatio: {
      type: [Number, String],
      default: 1,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    bgSize: {
      type: String,
      default: "cover",
    },
  },
  data() {
    return {
      pending: this.lazy,
      loadError: false,
      isLoaded: !this.lazy,
    };
  },
  computed: {
    isLoading() {
      const { lazy, pending } = this;
      if (!lazy) return false;
      return pending;
    },
    roootClasses() {
      const { isLoading, rounded } = this;
      const result = {
        "base-image--loading": isLoading,
        "base-image--rounded": rounded,
      };
      return result;
    },
    imageStyle() {
      const { isLoading, bgSize, src, aspectRatio } = this;
      const result = {
        backgroundSize: bgSize,
        paddingTop: `${aspectRatio * 100}%`,
      };
      if (!isLoading) {
        result.backgroundImage = `url("${src}")`;
      }
      return result;
    },
  },
  watch: {
    src: {
      handler(value) {
        if (value && this.lazy) {
          this.getImage();
        }
      },
      immediate: true,
    },
  },
  methods: {
    async getImage() {
      this.pending = true;
      this.loadError = false;
      this.isLoaded = false;
      try {
        await loadImage(this.src);
        this.isLoaded = true;
      } catch (e) {
        // whatever
        this.loadError = true;
      }
      this.pending = false;
    },
  },
};
</script>

<style lang="less">
.base-image {
  position: relative;
  overflow: hidden;
  &__cover {
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
    transition: opacity 0.3s;
    position: relative;
    z-index: 1;
  }

  &__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &--rounded {
    border-radius: 50%;
  }

  &--loading &__cover {
    opacity: 0;
  }
}
</style>
