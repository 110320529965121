import { request, talentRequest } from "@/services/api";
import Vue from "vue";
const EVENTS_CACHE_TTL = 3 * 60 * 60 * 1000; // 8 час
import { cacheProfiles, getCachedProfiles } from "@/utils/profile";
import { FINAL_STAGE } from "@/constants";
import chunk from "lodash/chunk";
import { eventSlice } from "@/utils/events";

const getEventsFromCache = () => {
  try {
    const sourceData = localStorage?.getItem("talent_events_list");
    if (sourceData) {
      return JSON.parse(sourceData);
    }
  } catch (error) {
    console.log("parseEventError", error);
  }
  return {};
};

export const profile = {
  namespaced: true,
  state: () => ({
    profiles: {},
    // Мероприятия из Таланта
    events: getEventsFromCache(),
    schedule: {},
  }),
  mutations: {
    /**
     * @param {object} state
     * @param {object} payload
     * @param {string} payload.key
     * @param {*} payload.value
     */
    SET_STATE(state, payload) {
      state[payload.key] = payload.value;
    },
    SET_EVENTS(state, payload) {
      state.events = { ...state.events, ...payload };
    },
    SET_SCHEDULE(state, payload) {
      Vue.set(state.schedule, payload.profile, payload.data);
    },
  },
  getters: {
    // Профили и спецпроекты
    allProfiles: (state) => {
      return Object.values(state.profiles);
    },
    // Спецпроекты
    specials: (state, getters) => {
      return getters.allProfiles.filter((n) => n.is_special_project);
    },
    // Нормальные профили
    profiles: (state, getters) => {
      return getters.allProfiles.filter((n) => !n.is_special_project);
    },
  },
  actions: {
    async getProfiles({ getters, commit, state, rootGetters }) {
      const user = rootGetters["user/user"];
      const isMentor = rootGetters["user/user"];
      const track = user?.participant?.track_id;
      const result = {};
      const cached = getCachedProfiles(user?.talent_id, track);
      if (cached) {
        commit("SET_STATE", {
          key: "profiles",
          value: cached,
        });
        return cached;
      }
      if (getters.allProfiles.length) return state.profiles;
      const { data } = await request({
        method: "GET",
        url: "/profiles",
        params: {
          page: 1,
          size: 99,
        },
      });
      if (Array.isArray(data?.items)) {
        data.items.reduce((acc, value) => {
          acc[value.id] = value;
          return acc;
        }, result);
        // сохраняем профили в LS на 3 часа
        if (user?.talent_id && (user?.participant?.id || isMentor)) {
          cacheProfiles(user.talent_id, track, result);
        }
      }
      commit("SET_STATE", {
        key: "profiles",
        value: result,
      });
      return state.profiles;
    },

    async getEvents({ state, commit }, ids) {
      // список Event которые мы еще не запрашивали
      const now = Date.now();
      const list = ids.filter((n) => {
        const event = state.events[n];
        if (!event || now - event._fetched_at >= EVENTS_CACHE_TTL) {
          return true;
        }
        return false;
      });

      if (!list.length) return;

      const { data } = await talentRequest({
        url: "/api/events/",
        params: {
          limit: 100,
          ids: list.join(","),
        },
      });

      if (data?.results?.length) {
        const newEvents = data.results.reduce((acc, event) => {
          acc[event.id] = {
            ...eventSlice(event),
            _fetched_at: Date.now(),
          };
          return acc;
        }, {});
        commit("SET_EVENTS", newEvents);
        localStorage?.setItem(
          "talent_events_list",
          JSON.stringify(state.events)
        );
      }
    },

    async getProfileSchedule({ state, commit }, profile_id) {
      if (state.schedule[profile_id]) {
        return state.schedule[profile_id];
      }
      const { data } = await request({
        method: "GET",
        url: "/schedule",
        params: {
          profile_id,
        },
      });
      let schedule = data?.items;
      if (schedule) {
        schedule = schedule.map(({ profile, ...rest }) => {
          return {
            ...rest,
            profile_id: profile.id,
          };
        });
        commit("SET_SCHEDULE", {
          profile: profile_id,
          data: schedule,
        });
      }
      return schedule;
    },
    /**
     * Получение данных о мероприятиях финалов
     * по всем профилям. Акутально для джуниор трека,
     * где перед выбором профиля, необходимо определить город финала
     */
    async getAllFinalEvents({ state, dispatch }) {
      const list = Object.values(state.profiles).reduce((acc, profile) => {
        if (Array.isArray(profile.steps)) {
          const finalEvents = profile.steps
            .filter(
              (step) => step.stage === FINAL_STAGE && step.talent_event_id
            )
            .map((finalStage) => finalStage.talent_event_id);
          return [...acc, ...finalEvents];
        }
        return acc;
      }, []);
      // У джунов может быть много мероприятий финала,
      // по этому разобьем список id а чанки по 80 штук
      const requests = chunk(list, 80).map((ids) => {
        return dispatch("getEvents", ids);
      });
      await Promise.all(requests);
    },
  },
};
