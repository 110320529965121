import { talentRequest } from "@/services/api";
import { userSerializer } from "@/utils/user";

export const users = {
  namespaced: true,
  state: () => ({
    list: {},
  }),
  mutations: {
    SET_USERS(state, payload) {
      state.list = { ...state.list, ...payload };
    },
  },
  actions: {
    /**
     * Получение списка пользователей
     * @param {*} ids
     */
    async fetchUsers({ state, commit }, ids) {
      if (!ids || !ids.length) return;
      const list = ids.filter((id) => {
        return !state.list[id];
      });
      if (!list.length) return;
      const { data } = await talentRequest({
        method: "GET",
        url: "/api/users/",
        params: {
          ids: list.join(","),
          limit: 100,
          offset: 0,
        },
      });
      if (data.results) {
        const users = data.results.reduce((acc, user) => {
          acc[user.id] = userSerializer(user);
          return acc;
        }, {});
        commit("SET_USERS", users);
      }
    },
  },
};
