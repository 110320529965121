import { withPrefix } from "./utils";
const baseUserMeta = {
  layout: "user",
  auth: true,
  participant: true,
};

const routes = [
  {
    path: "",
    name: "user-index",
    component: () => import("../views/user/Index.vue"),
    meta: baseUserMeta,
  },
  // Страница наставников
  {
    path: "/mentors",
    name: "user-mentors",
    component: () => import("../views/user/Mentors.vue"),
    meta: baseUserMeta,
  },
  // Страница c расписанием
  {
    path: "/schedule",
    name: "user-schedule",
    component: () => import("../views/user/Schedule.vue"),
    meta: baseUserMeta,
  },
  // Страница c профилями олимпиады
  {
    path: "/profiles",
    name: "user-profiles",
    component: () => import("../views/user/Profiles.vue"),
    meta: baseUserMeta,
  },
  {
    path: "/profiles/stage-1",
    name: "user-stage-1",
    component: () => import("../views/user/Stage.vue"),
    props: {
      stageNumber: 1,
    },
    meta: baseUserMeta,
  },
  {
    path: "/profiles/stage-2",
    name: "user-stage-2",
    component: () => import("../views/user/Stage.vue"),
    props: {
      stageNumber: 2,
    },
    meta: baseUserMeta,
  },
  {
    path: "/profiles/:profile_id/stage-1",
    name: "user-profile-stage-1",
    component: () => import("../views/user/Profile.vue"),
    props: {
      stageNumber: 1,
    },
    meta: baseUserMeta,
  },
  {
    path: "/profiles/:profile_id/stage-2",
    name: "user-profile-stage-2",
    component: () => import("../views/user/Profile.vue"),
    props: {
      stageNumber: 2,
    },
    meta: baseUserMeta,
  },
  {
    path: "/profiles/:profile_id/stage-3",
    name: "user-profile-stage-3",
    component: () => import("../views/user/Profile.vue"),
    props: {
      stageNumber: 3,
    },
    meta: baseUserMeta,
  },
  {
    path: "/account",
    name: "user-account",
    component: () => import("../views/Account.vue"),
    meta: baseUserMeta,
  },
  {
    path: "/notifications",
    name: "user-notifications",
    meta: baseUserMeta,
    component: () => import("../views/notifications/List.vue"),
  },
  {
    path: "/privileges",
    name: "user-privileges",
    meta: baseUserMeta,
    component: () => import("../views/user/Privileges.vue"),
  },
  {
    path: "/achievements",
    name: "user-achievements",
    meta: baseUserMeta,
    component: () => import("../views/user/Achievements.vue"),
  },
];

export const userRoutes = withPrefix("/user", routes);

export const userInvites = withPrefix("/invite", [
  {
    path: "/u/:code/:mentor/:org",
    name: "invait-participant",
    component: () => import("../views/invite/InviteParticipant.vue"),
    meta: {
      layout: "reg",
    },
  },
  {
    path: "/f/:code/:user",
    name: "invait-friend",
    component: () => import("../views/invite/InviteFriend.vue"),
    meta: {
      layout: "reg",
      silentAuth: true,
    },
  },
  {
    path: "/profile",
    name: "invite-profile",
    component: () => import("../views/invite/InviteProfile.vue"),
    meta: {
      layout: "reg",
      auth: true,
      participant: true,
    },
  },
  {
    path: "/team/:profile/:team/:code",
    name: "invite-team",
    component: () => import("../views/invite/InviteTeam.vue"),
    meta: {
      layout: "reg",
      silentAuth: true,
    },
  },
  {
    path: "/school",
    name: "invite-school",
    component: () => import("../views/invite/InviteToSchool.vue"),
    meta: {
      layout: "reg",
      silentAuth: true,
    },
  },
]);
