const CACHE_STORE = {};
const DEFAULT_CACHE_TTL = 2; // hours
const MAX_CACHE_TTL = 10; // Максимальное время жизни кеша
const MAX_CACHED_RESULTS = 100;
const EXPIRED_CACHE_SIZE = 10;

const getURL = (url, params) => {
  if (!url) return;
  if (!params) return url;
  return url + "?" + new URLSearchParams(params).toString();
};
/**
 * Небольшой стор для кеширования запросов к апи
 * запись протухает спустя DEFAULT_CACHE_TTL (2 часа) после установки
 * Этот параметр можно переопределить передав в setItem 4 арумент
 * `ttl` - число от 1 до 10 (кол-во часов)
 */
export const cacheServise = {
  getLength() {
    return Object.keys(CACHE_STORE).length;
  },
  /**
   * Получает тело ответа от апи,
   * по методу `url` с параметрами `params`
   * если оно есть в кеше
   * @param {string} url
   * @param {object?} params
   * @returns {*|undefined}
   */
  getItem(url, params) {
    const key = getURL(url, params);
    if (!key) return;
    const cached = CACHE_STORE[key];
    if (!cached) return;
    const expiredShift = (cached.ttl || DEFAULT_CACHE_TTL) * 60 * 60 * 1000;
    // Если кеш протух, то удаляем его
    if (Date.now() - cached.timestamp > expiredShift) {
      this.removeItem(key);
      return;
    }
    return cached.data;
  },
  /**
   * Запись тела ответа от АПИ в кеш
   * @param {string} url
   * @param {object?} params
   * @param {*} data
   * @param {number} ttl - время жизни кеша, в часах (hours) от 1 до MAX_CACHE_TTL = 10
   */
  setItem(url, params, data, ttl) {
    const key = getURL(url, params);
    if (!key) return;
    const item = {
      key,
      data,
      timestamp: Date.now(),
    };
    if (ttl && ttl > 0) {
      item.ttl = Math.min(MAX_CACHE_TTL, ttl);
    }
    CACHE_STORE[key] = item;
    // чекаем размер кеша
    const len = this.getLength();
    if (len >= MAX_CACHED_RESULTS) {
      this.dropOldest();
    }
  },
  removeItem(key) {
    delete CACHE_STORE[key];
  },
  dropCache() {
    Object.keys(MAX_CACHE_TTL).forEach((key) => {
      delete CACHE_STORE[key];
    });
  },
  /**
   * Удаляет самые старые `EXPIRED_CACHE_SIZE` записей из стора
   * @returns {number} новую длину стора
   */
  dropOldest() {
    const len = this.getLength();
    if (len > MAX_CACHED_RESULTS) {
      // Сортируем по дате создания и удаляем последние EXPIRED_CACHE_SIZE
      const sorted = Object.values(CACHE_STORE).sort((a, b) => {
        return b.timestamp - a.timestamp;
      });
      sorted.slice(0, EXPIRED_CACHE_SIZE).forEach((item) => {
        this.removeItem(item.key);
      });
    }
    return this.getLength();
  },
};
