<template>
  <div class="base-input-root">
    <div class="base-input-wrapper">
      <div
        :class="[`base-input--type-${type}`]"
        class="base-input">
        <div
          v-if="$slots.prepend"
          class="base-input__before">
          <slot name="prepend"></slot>
        </div>
        <textarea
          v-if="type === 'textarea'"
          v-bind="$attrs"
          :id="inputId"
          ref="field"
          :type="inputType"
          :class="classNames"
          :value="value"
          v-on="listeners"
          @input="handleUpdate">
        </textarea>
        <input
          v-else
          v-bind="$attrs"
          :id="inputId"
          ref="field"
          :type="inputType"
          :class="classNames"
          :value="value"
          v-on="listeners"
          @input="handleUpdate" />
        <div
          v-if="isPassword"
          class="base-input__after">
          <div
            class="pwd-toggle"
            @click="handleChangeType">
            <BaseIcon
              :title="
                currentType === 'text' ? 'Скрыть пароль' : 'Показать пароль'
              "
              :class="`pwd-toggle__icon--${currentType}`"
              :glyph="currentType === 'text' ? 'eye' : 'eye-close'"
              class="pwd-toggle__icon"
              width="20px"></BaseIcon>
          </div>
        </div>
        <div
          v-else
          class="base-input__after">
          <slot name="append"></slot>
        </div>
      </div>
    </div>
    <span
      v-if="errors && errors.length"
      class="form-error"
      >{{ errors[0] }}</span
    >
  </div>
</template>

<script>
export default {
  name: "BaseInput",
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: "text",
    },
    errors: {
      type: Array,
      default: () => [],
    },
    // Default, Second
    theme: {
      type: String,
      default: "default",
    },
    togglePassword: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number],
    },
    // Опции маски для ввода
    // @see https://nosir.github.io/cleave.js/
    format: {
      type: Object,
      default: null,
    },
    /**
     * @type String
     * @defaults md
     */
    size: {
      type: String,
      default: "md",
    },
    // Отправлять неформатированное value
    raw: {
      type: Boolean,
      default: false,
    },
    valid: Boolean,
    // eslint-disable-next-line vue/require-prop-types
    value: {},
  },
  data() {
    return {
      currentType: "password",
      filenames: null,
      isPassword: this.type === "password",
      innerId: "app-input-" + Date.now(),
    };
  },
  computed: {
    hasAppend() {
      const { $slots, $scopedSlots } = this;
      if ($slots.append) return true;
      if ($scopedSlots.append && typeof $scopedSlots.append === "function") {
        const content = $scopedSlots.append();
        return Boolean(content && content.length > 0);
      }
      return false;
    },
    hasPrepend() {
      const { $slots, $scopedSlots } = this;
      if ($slots.prepend) return true;
      if ($scopedSlots.prepend && typeof $scopedSlots.prepend === "function") {
        const content = $scopedSlots.prepend();
        return Boolean(content && content.length > 0);
      }
      return false;
    },
    inputType() {
      const { type, isPassword, currentType } = this;
      return isPassword ? currentType : type;
    },
    listeners() {
      /* eslint-disable no-unused-vars */
      const { input, ...rest } = this.$listeners;
      /* eslint-enable no-unused-vars */
      return rest;
    },
    classNames() {
      const baseClass = "base-input__control";
      const {
        togglePassword,
        errors,
        type,
        theme,
        valid,
        size,
        hasPrepend,
        hasAppend,
      } = this;

      return [
        baseClass,
        `${baseClass}--type-${type}`,
        `${baseClass}--size-${size}`,
        `${baseClass}--theme-${theme}`,
        {
          [`${baseClass}--has-toggle`]: togglePassword,
          [`${baseClass}--with-append`]: hasAppend,
          [`${baseClass}--with-prepend`]: hasPrepend,
          [`${baseClass}--has-error`]: errors.length,
          [`${baseClass}--is-valid`]: valid,
        },
      ];
    },
    inputId() {
      return this.id || this.innerId;
    },
  },
  mounted() {
    /* global Cleave */
    const { togglePassword, type } = this;
    if (togglePassword) {
      this.currentType = type;
    }

    if (this.format) {
      const input = this.$refs.field;
      this.cleave = new Cleave(input, {
        ...this.format,
        onValueChanged: this.handleFormatValueUpdate.bind(this),
      });
      if (this.value) {
        this.cleave.setRawValue(this.value);
      }
    }
  },

  created() {
    this.focus = () => {
      this.$refs.field.focus();
    };

    if (this.type === "file" && this.value) {
      this.filenames = [...this.value].map((n) => n.name).join(", ");
    }
  },

  beforeDestroy() {
    if (this.cleave) {
      this.cleave.destroy();
      this.cleave = null;
    }
  },
  methods: {
    handleFormatValueUpdate(e) {
      this.$nextTick(() => {
        let val = this.raw ? this.cleave.getRawValue() : e.target.value;
        this.$emit("input", val);
      });
    },
    handleUpdate(e) {
      if (this.format) return;
      this.$emit("input", e.target.value);
    },
    handleChangeType() {
      this.currentType = this.currentType === "text" ? "password" : "text";
    },
  },
};
</script>

<style lang="less" scoped>
.base-input {
  width: 100%;

  &-wrapper {
    position: relative;
  }

  &__control {
    width: 100%;
    border: @input-border-width solid @input-border-color;
    height: @input-height;
    padding: 0px @input-padding;
    box-shadow: none;
    font-size: @input-font-size;
    font-family: inherit;
    border-radius: @input-border-radius;
    transition: border-color 0.3s, color 0.3s;
    background-color: @input-bg-color;
    display: block;
    box-sizing: border-box;
    color: @input-text-color;
    appearance: none;

    &:hover {
      border-color: @input-hover-border-color;
    }

    &:focus {
      border-color: @input-focus-border-color;
      background-color: @input-focus-bg-color;
      color: @input-focus-text-color;
      outline: none;
    }

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: @input-placeholder-color;
      opacity: 1; /* Firefox */
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: @input-placeholder-color;
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: @input-placeholder-color;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: @input-text-color;
      border-color: @input-focus-border-color;
      box-shadow: 0 0 0px 1000px fade(@input-focus-border-color, 2%) inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    // &:-internal-autofill-selected {
    //   background-color: @input-bg-color !important;
    //   background-image: none !important;
    //   color: -internal-light-dark-color(black, white) !important;
    // }
    &--type-password {
      padding-right: 40px;
    }
    &--type-textarea {
      height: auto;
      resize: vertical;
      padding-top: @input-padding;
      padding-bottom: @input-padding;
      line-height: 1.32;
      min-height: @input-height;
    }

    &--with-prepend {
      padding-left: @input-padding + @input-prepend-width;
    }

    &--with-append {
      padding-right: @input-padding + @input-append-width;
    }

    &--is-valid {
      border-color: @input-success-border-color !important;
    }

    &--has-error {
      border-color: @input-error-border-color !important;
    }

    &:disabled {
      background-color: #fff !important;
      border-color: @input-disabled-color !important;
      color: @input-disabled-color;

      &::placeholder {
        color: @input-disabled-color;
      }

      &:-ms-input-placeholder {
        color: @input-disabled-color;
      }

      &::-ms-input-placeholder {
        color: @input-disabled-color;
      }
    }
  }

  &__after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: @input-height;
    .fz(16);

    &:empty {
      display: none;
    }
  }

  &__before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: @input-height;
    &:empty {
      display: none;
    }
  }

  &--type-number &__control {
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .pwd-toggle {
    user-select: none;
    &__icon {
      color: @base-color;
      cursor: pointer;

      &--password {
        color: fade(gray, 10%);
      }
    }
  }

  &__control--theme-reg {
    border-color: @reg-input-border-color;
    height: @reg-input-height;
    background-color: @reg-input-bg-color;
    color: @reg-input-text-color;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: fade(#fff, 80%);
      opacity: 1; /* Firefox */
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: fade(#fff, 80%);
    }

    &:focus {
      background-color: @reg-input-bg-color;
      border-color: @reg-input-focus-border-color;
      color: @reg-input-text-color;
    }

    &:hover {
      border-color: @reg-input-focus-border-color;
    }
  }

  &__control--theme-reg:-webkit-autofill,
  &__control--theme-reg:-webkit-autofill:hover,
  &__control--theme-reg:-webkit-autofill:focus {
    -webkit-text-fill-color: #fff;
    border-color: @reg-input-border-color !important;
    box-shadow: 0 0 0px 1000px fade(#000, 0%) inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}
</style>
