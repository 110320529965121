<template>
  <BaseImage
    class="base-avatar"
    :aspect-ratio="1"
    rounded
    :src="image">
    <template #placeholder>
      <span class="base-avatar__placeholder">
        <svg
          class="base-avatar__icon"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 36 32">
          <path
            v-if="gender === 'w'"
            d="M22.22 20.726c-0.157-0.024-0.317 0.038-0.425 0.155-0.109 0.116-0.156 0.278-0.126 0.435 0.276 1.45 1.205 3.44 4.057 4.679 0.338 0.146 0.86 0.26 1.522 0.403 2.478 0.536 6.622 1.434 7.64 5.232 0.060 0.223 0.262 0.37 0.483 0.37 0.043 0 0.086-0.006 0.13-0.017 0.267-0.072 0.425-0.346 0.354-0.613-1.177-4.387-5.872-5.404-8.395-5.95-0.584-0.127-1.089-0.236-1.336-0.344-1.714-0.744-2.821-1.848-3.301-3.286 4.762 0.354 6.918-1.543 7.013-1.629 0.118-0.107 0.179-0.266 0.161-0.425s-0.11-0.3-0.249-0.38c-2.748-1.57-2.748-6.995-2.748-9.038 0-5.713-3.848-10.242-8.781-10.313-0.014-0.001-0.121-0.003-0.141-0.003 0 0 0 0-0.001 0-5.005 0.028-9.077 4.562-9.077 10.108 0 2.043 0 7.469-2.748 9.038-0.145 0.083-0.238 0.232-0.251 0.398-0.012 0.166 0.060 0.328 0.191 0.431 0.138 0.107 3.188 2.441 6.941 1.732-0.502 1.378-1.594 2.438-3.258 3.161-0.241 0.105-0.721 0.22-1.277 0.352-2.54 0.604-7.269 1.729-8.453 6.147-0.071 0.268 0.087 0.541 0.354 0.613 0.271 0.067 0.541-0.088 0.613-0.354 1.029-3.844 5.217-4.839 7.718-5.435 0.627-0.149 1.122-0.267 1.444-0.406 2.852-1.239 3.78-3.229 4.057-4.679 0.033-0.173-0.027-0.351-0.159-0.467-0.131-0.117-0.312-0.157-0.482-0.104-2.707 0.852-5.208-0.32-6.301-0.97 2.611-2.186 2.611-7.217 2.611-9.457 0-4.997 3.626-9.083 8.12-9.11 0.005 0 0.102 0.006 0.106 0.006 4.432 0.063 7.774 4.066 7.774 9.312 0 2.241 0 7.283 2.622 9.469-0.903 0.516-2.962 1.351-6.402 0.939z"></path>
          <path
            v-else
            d="M0.5 31.983c0.268 0.067 0.542-0.088 0.612-0.354 1.030-3.843 5.216-4.839 7.718-5.435 0.627-0.149 1.122-0.267 1.444-0.406 2.85-1.237 3.779-3.227 4.057-4.679 0.034-0.175-0.029-0.355-0.165-0.473-1.484-1.281-2.736-3.204-3.526-5.416-0.022-0.063-0.057-0.121-0.103-0.171-1.045-1.136-1.645-2.337-1.645-3.294 0-0.559 0.211-0.934 0.686-1.217 0.145-0.087 0.236-0.24 0.243-0.408 0.221-5.094 3.849-9.104 8.299-9.13 0.005 0 0.102 0.007 0.107 0.007 4.472 0.062 8.077 4.158 8.206 9.324 0.004 0.143 0.068 0.277 0.178 0.369 0.313 0.265 0.459 0.601 0.459 1.057 0 0.801-0.427 1.786-1.201 2.772-0.037 0.047-0.065 0.101-0.084 0.158-0.8 2.536-2.236 4.775-3.938 6.145-0.144 0.116-0.212 0.302-0.178 0.483 0.278 1.451 1.207 3.44 4.057 4.679 0.337 0.146 0.86 0.26 1.523 0.403 2.477 0.536 6.622 1.435 7.639 5.232 0.060 0.223 0.262 0.37 0.482 0.37 0.043 0 0.086-0.006 0.13-0.017 0.267-0.072 0.425-0.346 0.354-0.613-1.175-4.387-5.871-5.404-8.393-5.95-0.585-0.127-1.090-0.236-1.336-0.344-1.86-0.808-3.006-2.039-3.411-3.665 1.727-1.483 3.172-3.771 3.998-6.337 0.877-1.14 1.359-2.314 1.359-3.317 0-0.669-0.216-1.227-0.644-1.663-0.238-5.604-4.237-10.017-9.2-10.088l-0.149-0.002c-4.873 0.026-8.889 4.323-9.24 9.83-0.626 0.46-0.944 1.105-0.944 1.924 0 1.183 0.669 2.598 1.84 3.896 0.809 2.223 2.063 4.176 3.556 5.543-0.403 1.632-1.55 2.867-3.414 3.676-0.241 0.105-0.721 0.22-1.277 0.352-2.541 0.604-7.269 1.729-8.453 6.147-0.071 0.267 0.087 0.54 0.354 0.612z"></path>
        </svg>
      </span>
    </template>
  </BaseImage>
</template>

<script>
export default {
  name: "UserAvatar",
  props: {
    image: {
      type: String,
    },
    gender: {
      type: String,
      default: "m",
      validator: function (val) {
        return ["m", "w"].includes(val);
      },
    },
  },
};
</script>

<style lang="less">
.base-avatar {
  &__placeholder {
    background-color: @bg-light;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__icon {
    width: 72%;
    height: 72%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: currentColor;
  }
}
</style>
