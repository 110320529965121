<template>
  <div
    :class="[{ 'is-loading': pending }, `base-loading-box--theme-${theme}`]"
    class="base-loading-box"
    :style="{ minHeight }">
    <div
      v-if="pending"
      class="base-loading-box__overlay">
      <BaseSpinner
        size="32px"
        brand />
    </div>
    <div class="base-loading-box__data">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseLoadingBox",
  props: {
    pending: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: "default",
    },
    minHeight: {
      type: String,
      default: "32px",
    },
  },
};
</script>

<style lang="less">
.base-loading-box {
  position: relative;

  &.is-loading {
    overflow: hidden;
  }

  &.is-loading &__data {
    filter: blur(3px);
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: fade(#fff, 70%);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--theme-reg &__overlay {
    background-color: fade(#fff, 10%);
  }

  &--theme-primary &__overlay {
    background-color: fade(@primary-color, 10%);
  }
}
</style>
