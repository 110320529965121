/**
 * Загрузка картинки
 * @param {string} source - путь до файла
 * @returns Promise
 */
export const loadImage = (source) => {
  if (!source) return Promise.reject("no source passed");

  // На сервере просто возвращаем путь сразу
  if (typeof window === "undefined") return Promise.resolve(source);

  if (typeof source !== "string") {
    return Promise.reject("source must be a string");
  }
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      if (img.naturalWidth && img.naturalWidth > 0) {
        resolve(source);
      } else {
        reject("not an image");
      }
    };

    img.onerror = function () {
      reject();
    };

    img.src = `${source}`;
  });
};

/**
 * Copies to Clipboard value
 * @param {String} valueForClipboard value to be copied
 * @param {Boolean} isIOS is current browser is Ios (Mobile Safari)
 * @return {boolean} shows if copy has been successful
 */
export const copyToClipboard = (valueForClipboard) => {
  const isIOS = navigator.userAgent.match(/ipad|ipod|iphone/i);
  const textArea = document.createElement("textarea");
  textArea.value = valueForClipboard;

  textArea.style.position = "absolute";
  textArea.style.left = "-9999px"; // to make it invisible and out of the reach
  textArea.setAttribute("readonly", ""); // without it, the native keyboard will pop up (so we show it is only for reading)

  document.body.appendChild(textArea);

  if (isIOS) {
    const range = document.createRange();
    range.selectNodeContents(textArea);

    const selection = window.getSelection();
    selection.removeAllRanges(); // remove previously selected ranges
    selection.addRange(range);
    textArea.setSelectionRange(0, valueForClipboard.length); // this line makes the selection in iOS
  } else {
    textArea.select(); // this line is for all other browsers except ios
  }

  try {
    return document.execCommand("copy"); // if copy is successful, function returns true
  } catch (e) {
    return false; // return false to show that copy unsuccessful
  } finally {
    document.body.removeChild(textArea); // delete textarea from DOM
  }
};

export const normalizeByKey = (list, key = "id") => {
  if (!list || !Array.isArray(list)) return;
  return list.reduce((acc, value) => {
    acc[value[key]] = value;
    return acc;
  }, {});
};

/**
 * Задержка в милисекундах
 * @param {number} ms - количество милисекунд
 * @returns {promise}
 */
export const delay = (ms = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
};
export const isInt = (n) => parseInt(n) === n;
export const isFloat = (n) => {
  return typeof n === "number" && !isInt(n);
};

/**
 * Возвращает правильный падеж для числительного (им п, )
 * @param {string[]} titles - массив строк lenght = 3
 * @param {number} num - число
 * @return string
 */
export const numCases = (titles, num) => {
  // если число - не целое то вернем сразу 2 ключ, так как
  // дробное число родительный падеж (-десятых, -сотых, -тысячных).
  if (isFloat(num)) return titles[1];

  // Эта часть просто спизжена от куда-то =)
  const cases = [2, 0, 1, 1, 1, 2];
  const val = Math.abs(num);
  const current =
    val % 100 > 4 && val % 100 < 20 ? 2 : cases[val % 10 < 5 ? val % 10 : 5];
  return titles[current];
};

/**
 * Сериализует объект в queryString
 * массив разбирается по правилу 'repeat'
 * @param {object} params
 * @returns {string}
 */
export const serializeParamsWithRepeat = (params) => {
  if (!params) return "";
  return Object.entries(params)
    .reduce((acc, [key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((val) => {
          acc += `&${key}=${val}`;
        });
      } else {
        acc += `&${key}=${value}`;
      }
      return acc;
    }, "")
    .slice(1);
};

/**
 * qs
 * @param {Object} params
 * @returns {String} querySting
 */
export const serializeParams = (params = {}) => {
  return Object.entries(params)
    .reduce((acc, [key, value]) => {
      if (Array.isArray(value)) {
        acc.push(`${key}=${value.join(",")}`);
      } else {
        acc.push(`${key}=${value}`);
      }
      return acc;
    }, [])
    .join("&");
};

/**
 * Получает размер скролбара
 * @returns {number} размер скроллбара
 */
export const getScrollBarWidth = () => {
  // Creating invisible container
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll"; // forcing scrollbar to appear
  outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement("div");
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);
  return scrollbarWidth;
};

export const bytesToMB = (bytes, accuracy = 2) => {
  return (bytes * 0.000001).toFixed(accuracy);
};
/**
 *
 * @param {string[]} dates
 * @returns {string}
 */
export const getMinDate = (dates) => {
  if (!Array.isArray(dates)) return;
  return dates.filter(Boolean).sort((a, b) => {
    return new Date(a) >= new Date(b) ? 1 : -1;
  })[0];
};

/**
 *
 * @param {string[]} dates
 * @returns {string}
 */
export const getMaxDate = (dates) => {
  if (!Array.isArray(dates)) return;
  return dates.filter(Boolean).sort((a, b) => {
    return new Date(b) >= new Date(a) ? 1 : -1;
  })[0];
};

export const asyncCorsLinkOpen = async (url) => {
  console.log(2222, url);
  await delay(1000);
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  // link.style.display = "none";
  document.body.appendChild(link);
  console.log(link, "link");
  link.click();
  window.open(url, "_blank");
};
