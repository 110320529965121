<template>
  <div class="modal-content">
    <button
      v-if="closable"
      class="close-btn"
      title="Закрыть"
      @click="handleClose">
      <BaseIcon glyph="close" />
    </button>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BaseModalContent",
  props: {
    name: {
      type: String,
    },
    dynamic: {
      type: Boolean,
    },
    closable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleClose() {
      if (this.dynamic) {
        this.$emit("close");
        return;
      }
      if (this.name) {
        this.$modal.hide(this.name);
        this.$emit("onClose");
        return;
      }
      this.$modal.hide();
    },
  },
};
</script>

<style lang="less" scoped>
.close-btn {
  .btn-reset();
  position: absolute;
  top: 20px;
  right: 25px;
  .fz(30);

  &:hover {
    color: @primary-color;
  }
}
</style>
