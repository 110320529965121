/**
 * @param {Object} user
 * @returns {Object} userinfo
 */
export const userSerializer = (user) => {
  const {
    first_name,
    last_name,
    middle_name,
    sex = "m",
    address,
    birthday,
    avatar,
    city,
    email,
    id,
    region_with_type,
  } = user;
  const full_name = [first_name, middle_name, last_name]
    .filter(Boolean)
    .join(" ");
  const short_name = [first_name, `${(last_name || "")[0]}.`]
    .filter(Boolean)
    .join(" ");
  return {
    first_name,
    last_name,
    middle_name,
    sex,
    address,
    birthday,
    full_name,
    short_name,
    avatar,
    city,
    email,
    talent_id: id,
    region_with_type,
  };
};
