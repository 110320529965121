<template>
  <div
    class="base-alert"
    :class="rootClass">
    <div class="base-alert__icon">
      <BaseIcon :glyph="currentIcon" />
    </div>
    <div class="base-alert__content typography">
      <slot></slot>
    </div>
  </div>
</template>

<script>
const iconsMap = {
  info: "info-rounded",
  success: "outline-success",
  error: "danger-hex",
};

export default {
  name: "BaseAlert",
  props: {
    type: {
      type: String,
      default: "info", // info, success, error
    },
    glyph: {
      type: String,
      default: "",
    },
  },
  computed: {
    rootClass() {
      return `base-alert--${this.type}`;
    },
    currentIcon() {
      if (this.glyph) {
        return this.glyph;
      }
      return iconsMap[this.type];
    },
  },
};
</script>

<style lang="less" scoped>
.base-alert {
  border-left: 4px solid #dedede;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: @shadow-default;
  padding: @panel-padding;

  &__icon {
    margin-right: 10px;
    flex-grow: 0;
    font-size: 26px;
  }

  &__content {
    flex-grow: 1;
    min-width: 1px;
  }

  &--info {
    border-left-color: @link-blue;
  }
  &--info &__icon {
    color: @link-blue;
  }
  &--success {
    border-left-color: @success;
  }
  &--success &__icon {
    color: @success;
  }
  &--error {
    border-left-color: @danger;
  }
  &--error &__icon {
    color: @danger;
  }
}
</style>
