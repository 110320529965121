import Vue from "vue";
import VueRouter from "vue-router";
import { authorizeMiddleware, silentAuthMiddleware } from "./middlewares/auth";
import { setLayout } from "./middlewares/layout";
import { participantMiddleware, mentorMiddleware } from "./middlewares/role";
import Authorize from "@/views/Authorize";
import { userRoutes, userInvites } from "./userRoutes";
import { mentorRoutes, mentorInvites } from "./mentorRoutes";
import PageLogin from "@/views/Login";
import RequireConsent from "@/views/RequireConsent";
import BannedParticipant from "@/views/BannedParticipant";
import UnderConstruct from "../views/UnderConstruct.vue";
import ExternalRedirect from "@/views/ExternalRedirect.vue";

const SERVICE_UNAVAILABLE = false;

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "index",
    meta: { layout: "landing", silentAuth: true },
    component: () =>
      import(/* webpackChunkName: "landing" */ "../views/Landing.vue"),
  },
  // редирект после авторизации
  {
    path: "/auth",
    name: "auth",
    component: Authorize,
    meta: { layout: "reg" },
  },
  {
    path: "/login",
    name: "login",
    component: PageLogin,
    meta: { layout: "reg", silentAuth: true },
  },
  {
    path: "/registration",
    meta: { layout: "reg" },
    component: () => import("../views/registration/Index.vue"),
    children: [
      {
        path: "",
        meta: { layout: "reg" },
        name: "registration",
        component: () => import("../views/registration/Roles.vue"),
      },
      {
        path: "school",
        name: "registration-school",
        meta: { layout: "reg" },
        component: () => import("../views/registration/Participant.vue"),
      },
      {
        path: "student",
        name: "registration-student",
        meta: { layout: "reg" },
        component: () => import("../views/registration/Participant.vue"),
      },
      {
        path: "mentor",
        name: "registration-mentor",
        meta: { layout: "reg" },
        component: () => import("../views/registration/Mentor.vue"),
      },
      {
        path: "login",
        name: "registration-login",
        meta: { layout: "reg" },
        component: () => import("../views/registration/Login.vue"),
      },
      {
        path: "profile",
        name: "registration-profile",
        meta: { layout: "reg" },
        component: () => import("../views/registration/Profile.vue"),
      },
    ],
  },
  ...userRoutes,
  ...mentorRoutes,
  ...mentorInvites,
  ...userInvites,
  {
    path: "/require-consent",
    name: "require-consent",
    component: RequireConsent,
    meta: { layout: "reg" },
  },
  {
    path: "/banned-participant",
    name: "banned-participant",
    component: BannedParticipant,
    meta: { layout: "reg" },
  },
  {
    path: "/consent_intruder",
    name: "consent_intruder",
    component: PageLogin,
    meta: { layout: "reg", silentAuth: false },
    beforeEnter: (to) => {
      window.location.href =
        process.env.VUE_APP_TALENT_BASE_URL +
        "/consent_intruder" +
        `?return_url=${encodeURIComponent(
          window.location.origin + to.query.next || "/"
        )}`;
    },
  },
  {
    path: "/support",
    name: "support",
    component: () => import("../views/help/Help.vue"),
  },
  {
    path: "/final_registration",
    name: "final_registration",
    component: () => import("../views/final_reg/Index.vue"),
    meta: { layout: "reg" },
  },
  {
    path: "/7-up",
    name: "7up",
    component: () => import("../views/7upRegistration.vue"),
    meta: { layout: "reg" },
  },

  {
    path: "/principal",
    name: "principal",
    meta: { layout: "principal", silentAuth: true },
    component: () =>
      import(/* webpackChunkName: "principal" */ "../views/Principal.vue"),
  },
  {
    path: "/principal/registration",
    name: "principal_reg",
    meta: { layout: "reg", silentAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "principal" */ "../views/PrincipalRegistration.vue"
      ),
  },
  {
    path: "/principal/error",
    name: "principal_error",
    meta: { layout: "reg", silentAuth: true },
    component: () =>
      import(/* webpackChunkName: "principal" */ "../views/PrincipalError.vue"),
  },
  {
    path: "/external-redirect",
    name: "external-redirect",
    component: ExternalRedirect,
    meta: { layout: "system" },
  },
  {
    path: "*",
    name: "page-404",
    component: () => import("../views/PageNotFound.vue"),
    meta: { layout: "reg", silentAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes: SERVICE_UNAVAILABLE
    ? [
        {
          path: "*",
          name: "under-construct",
          meta: { layout: "reg", silentAuth: false },
          component: UnderConstruct,
        },
      ]
    : routes,
});

router.beforeEach(silentAuthMiddleware);
router.beforeEach(authorizeMiddleware);
router.beforeEach(participantMiddleware);
router.beforeEach(mentorMiddleware);
router.beforeEach(setLayout);

export default router;
