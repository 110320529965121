import axios from "axios";
import { getCookie } from "@/utils/cookies";
import { APIError } from "./utils/talent";
import { config } from "@/config";

let is418Redirect = false;
let is403Redirect = false;

const ONTI_API_INSTANCE = axios.create({ baseURL: config.API_PREFIX });
const CLIENT_API_INSTANCE = axios.create({
  baseURL: config.CLIENT_API_PREFIX,
});
const TALENT_API_INSTANCE = axios.create({
  baseURL: config.CLIENT_TALENT_PREFIX,
});

const calconInstance = axios.create({
  baseURL: config.CLIENT_CALCON_PREFIX,
});

export const getToken = () => getCookie("front_access_token");

export const useToken = (cfg) => {
  const token = getToken();
  if (!cfg) {
    cfg = {};
  }
  if (token) {
    cfg.headers["Authorization"] = `Bearer ${token}`;
  }
  return cfg;
};

/**
 * Дефолтный реквест к апи ОНТИ
 */
export const request = ONTI_API_INSTANCE;
ONTI_API_INSTANCE.interceptors.request.use(useToken);
CLIENT_API_INSTANCE.interceptors.request.use(useToken);

ONTI_API_INSTANCE.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error.config);
    const status = error?.response?.status;
    const href = window?.location?.pathname;
    if (status === 418 && !is418Redirect) {
      is418Redirect = true;
      const isSamePage =
        window.location.href.split("/")[3] === "require-consent";
      let url = `${window.location.origin}/require-consent`;
      if (!isSamePage && href) {
        url + `?next=${encodeURIComponent(href)}`;
      }
      window.location.href = url;
    }

    if (
      status === 403 &&
      error?.response?.data?.detail === "Вы забанены" &&
      !is403Redirect
    ) {
      is403Redirect = true;
      let url = `${window.location.origin}/banned-participant`;
      window.location.href = url;
    }
    // $sentry.addBreadcrumb({
    //   category: "api_failed_request",
    //   message: `Failed api request url ${originalRequest.url} with status ${status}`,
    //   level: $sentry.Severity.Info,
    // });
    return Promise.reject(new APIError(error));
  }
);

TALENT_API_INSTANCE.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(new APIError(error));
  }
);

TALENT_API_INSTANCE.interceptors.request.use(useToken);

calconInstance.interceptors.request.use(useToken);
calconInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(new APIError(error));
  }
);

/**
 * Реквест к АПИ Таланта, через прокси сервер
 */
export const talentRequest = TALENT_API_INSTANCE;

/**
 * Реквест к Backend-for-Frontend
 */
export const clientApiRequest = CLIENT_API_INSTANCE;
export const calconApiRequest = calconInstance;
