import { clientApiRequest } from "../api";
import { serializeParamsWithRepeat } from "@/utils";
/**
 *
 * @see https://stage.ntcontest.ru/docs/v5/#operation/EducationalBlockList
 */
export const getEducationBlocks = (params) => {
  const qs = serializeParamsWithRepeat(params);
  return clientApiRequest({
    method: "GET",
    url: `/edublock?${qs}`,
    baseURL: "/v5",
  });
};
