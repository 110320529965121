import findIndex from "lodash/findIndex";
import {
  MSG_STATUS_SENT,
  ALL_MSG_TYPES,
  MSG_STATUS_DELIVERED,
} from "@/constants/notifications";
import { talentRequest } from "@/services/api";
import { numCases } from "@/utils";
import { noticeDispatcher } from "@/services/notifications";
const MAX_NOTICE_PER_STACK_COUNT = 2;

const isValidMessage = (msg) => {
  if (!msg) return false;
  return typeof msg === "object" && ALL_MSG_TYPES.includes(msg.type);
};

export const notifications = {
  namespaced: true,
  state: () => ({
    isConnected: false,
    messages: [],
    reconnectError: false,
    useNativeNotices: true,
    settings: null,
  }),
  mutations: {
    SOCKET_ONOPEN(state) {
      state.isConnected = true;
    },
    SOCKET_ONCLOSE(state) {
      state.isConnected = false;
    },
    SOCKET_ONERROR(state, event) {
      console.error(state, event);
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
      state.messages.push(message);
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
      console.info("try to reconnect to socket server. Try count", count);
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.reconnectError = true;
    },
    DELETE_MESSAGE(state, id) {
      const idx = findIndex(state.messages, (n) => n.id === id);
      if (idx >= 0) {
        state.messages.splice(idx, 1);
      }
    },
    /**
     * @param {object} state
     * @param {object} payload
     * @param {string} payload.key
     * @param {*} payload.value
     */
    SET_STATE(state, payload) {
      state[payload.key] = payload.value;
    },

    UPDATE_SETTING(state, payload) {
      if (!state.settings || !state.settings.length) {
        state.settings = [payload];
        return;
      }
      const currentIdx = findIndex(state.settings, (n) => {
        return n.destination === payload.destination;
      });
      if (currentIdx >= 0) {
        state.settings.splice(currentIdx, 1, payload);
      } else {
        state.settings.push(payload);
      }
    },
    SOCKET_ONMESSAGE_ARRAY(state, payload) {
      if (Array.isArray(payload)) {
        const newMessages = payload.map((n) => {
          return {
            ...n,
            status: MSG_STATUS_SENT,
            send_at: new Date().toString(),
          };
        });
        state.messages = [...state.messages, ...newMessages];
      }
    },
  },
  actions: {
    showNotice(context, data) {
      noticeDispatcher.dispatch(data.subject, data.text);
    },
    receiveMessage({ commit, dispatch }, data) {
      // при начальном подключении, бек шлет в socket
      // все НЕПОЛУЧЕННЫЕ сообщения в Массиве [].
      // Поэтому надо показать, в виде нотисов
      // только `MAX_NOTICE_PER_STACK_COUNT` сообщений.
      // при этом записав в store весь стек
      if (Array.isArray(data)) {
        const validatedMessages = data.filter(isValidMessage).map((n) => {
          return {
            ...n,
            status: MSG_STATUS_DELIVERED,
            send_at: new Date().toString(),
          };
        });
        if (!validatedMessages.length) return;
        commit("SOCKET_ONMESSAGE_ARRAY", validatedMessages);
        const listToShow = validatedMessages.slice(
          0,
          MAX_NOTICE_PER_STACK_COUNT
        );
        listToShow.forEach((item) => {
          dispatch("showNotice", item);
        });
        if (validatedMessages.length > MAX_NOTICE_PER_STACK_COUNT) {
          const count = validatedMessages.length - MAX_NOTICE_PER_STACK_COUNT;
          dispatch("showNotice", {
            subject: "Новые уведомления",
            text: `Получено еще ${count} ${numCases(
              ["новое сообщение", "новых сообщения", "новых сообщений"],
              count
            )}`,
          });
        }
      } else {
        if (isValidMessage(data)) {
          const savedMsg = {
            ...data,
            status: MSG_STATUS_DELIVERED,
            send_at: new Date().toString(),
          };
          commit("SOCKET_ONMESSAGE", savedMsg);
          dispatch("showNotice", data);
          // отправляем в сокет инфу что доставлено
          dispatch("setMessageStatus", {
            id: savedMsg.id,
            status: savedMsg.status,
          });
        }
      }
    },

    async getSettings({ state, commit }, noCache = false) {
      if (state.settings && !noCache) return state.settings;
      const { data } = await talentRequest({
        url: "/notifications/subscriptions",
      });

      if (data && Array.isArray(data) && data.length) {
        commit("SET_STATE", {
          key: "settings",
          value: data,
        });
      }
    },

    async saveSettings({ commit }, payload) {
      const { data } = await talentRequest({
        url: "/notifications/subscriptions",
        method: "POST",
        data: payload,
      });

      if (data) {
        commit("UPDATE_SETTING", data);
      }
    },
    /**
     *
     * @param {string} messageId
     * @param {string} status
     */
    async setMessageStatus(context, { id, status }) {
      return talentRequest({
        url: `/notifications/status`,
        method: "PUT",
        data: {
          id,
          status,
        },
      });
    },
  },
  getters: {
    msgCount(state) {
      return state.messages.length;
    },
  },
};
