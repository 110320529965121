import Vue from "vue";
import Vuex from "vuex";
import { config } from "@/config";
import { ui } from "./modules/ui.js";
import { user } from "./modules/user.js";
import { profile } from "./modules/profile.js";
import { mentor } from "./modules/mentor.js";
import { participant } from "./modules/participant.js";
import { users } from "./modules/users.js";
import { notifications } from "./modules/notifications.js";
import { request, talentRequest } from "@/services/api";
import { normalizeByKey, serializeParams } from "@/utils";
import { SCHOOL_ALIAS } from "@/constants/index.js";

const tracks_ttl = 100 * 24 * 60 * 60 * 1000; // Время жизни треков в LocalStorage 100 дней
const initialTracks = () => {
  try {
    const tracks = localStorage?.getItem("tracks");
    if (tracks) {
      const result = JSON.parse(tracks);
      // если кеш не старше чем `tracks_ttl`
      if (Date.now() - result.ts < tracks_ttl) {
        return result.tracks;
      }
    }
    return {};
  } catch (error) {
    return {};
  }
};

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    clientApiPrefix: config.CLIENT_API_PREFIX,
    ntoToolsApiURL: process.env.VUE_APP_NTO_TOOLS_API_URL,
    season: null,
    tracks: initialTracks(),
    release: process.env.VUE_APP_VERSION,
    schedule: null,
    syncTimeStamp: Date.now(),
    allowTelegram: true,
    achievementRoles: {},
    tgBotVideo: [
      {
        title: "Подключение телеграма к ЛК НТО",
        url: "https://youtu.be/9FPs4Nm0pMA",
      },
      {
        title: "Как создать команду?",
        url: "https://youtu.be/eo2_ibxpIsY",
      },
      {
        title: "Как найти команду?",
        url: "https://youtu.be/b-XKqxpfDw0",
      },
    ],
    banners: {},
    talentURL: process.env.VUE_APP_TALENT_BASE_URL,
    supportEmail: "help@ntcontest.ru",
    preSeasonEventId: 1234,
  },
  getters: {
    rootEvent: (state) => {
      const { season } = state;
      return season?.talent_event;
    },
    seasonRange: (state) => {
      const { season } = state;
      if (season?.start_at) {
        const year = new Date(season.start_at).getFullYear();
        return `${year}/${year + 1}`;
      }
      return undefined;
    },
    schoolTrackId: (state) => {
      return Object.values(state.tracks).find((t) => t.alias === SCHOOL_ALIAS)
        ?.id;
    },
    /**
     * @returns 'ended' | 'started'
     */
    seasonStatus: (state) => {
      const season = state.season;
      if (!season) return "ended";
      const now = Date.now();
      if (season.end_at && now - new Date(season.end_at).getTime() >= 0) {
        return "ended";
      } else if (
        season.start_at &&
        now - new Date(season.start_at).getTime() > 0
      ) {
        return "started";
      }
      return "ended";
    },
  },
  mutations: {
    SET_STATE(state, payload) {
      state[payload.key] = payload.value;
    },
    SET_BANNERS(state, payload) {
      state.banners = {
        ...state.banners,
        [payload.key]: payload.data,
      };
    },
  },
  actions: {
    /**
     * Получение текущего сезона Олимпиады
     */
    async getSeason({ commit, state }, params = {}) {
      if (state.season && !params.ignoreCache) return state.season;
      const { data } = await request({
        method: "GET",
        url: "/season",
      });
      commit("SET_STATE", {
        key: "season",
        value: data,
      });
      return data;
    },
    async getSchedule({ commit, state }) {
      if (state.schedule) return state.schedule;
      const { data } = await request({
        method: "GET",
        url: "/schedule",
        params: {
          size: 100,
        },
      });

      commit("SET_STATE", {
        key: "schedule",
        value: data.items,
      });

      return state.schedule;
    },
    /**
     * Получение списка треков Олимпиады
     */
    async getTracks({ commit, state }) {
      if (Object.values(state.tracks).length) return;
      const { data } = await request({
        method: "GET",
        url: "/tracks",
      });
      if (data.items.length) {
        const result = normalizeByKey(data.items);
        localStorage?.setItem(
          "tracks",
          JSON.stringify({ ts: Date.now(), tracks: result })
        );
        commit("SET_STATE", {
          key: "tracks",
          value: result,
        });
      }
    },
    async getBanners({ commit, state }, params = {}) {
      const key = serializeParams(params) || "default";
      const banners = state.banners[key];
      if (banners) return banners;
      const { data } = await request({
        method: "get",
        url: "/banners",
        params,
      });
      commit("SET_BANNERS", {
        key,
        data,
      });
      return data;
    },

    async getAchievementRoles({ commit, state }) {
      if (Object.keys(state.achievementRoles).length) return;
      const { data } = await talentRequest({
        method: "GET",
        url: "/api/achievements/roles/",
        params: {
          limit: 90,
        },
      });
      commit("SET_STATE", {
        key: "achievementRoles",
        value: normalizeByKey(data.results || [], "id"),
      });
    },
  },
  modules: {
    ui,
    user,
    profile,
    mentor,
    participant,
    users,
    notifications,
  },
});
