export const parseUserAchievement = (achievement) => {
  return {
    diploma_link: achievement.diploma_link,
    id: achievement.id,
    max_score: achievement.max_score,
    score: achievement.score,
    role: achievement.role,
    status: achievement.status,
    event_id: achievement.event?.id,
    event_brands: achievement.event?.brands,
    event_talent_roles: achievement.event?.event_talent_roles,
    event_title: achievement.event?.title,
    event_routes: achievement.event?.routes,
    talent_roles: achievement.event?.talent_roles,
  };
};

export const mapCalconAchievement = (achievement) => {
  return {
    diploma_link: achievement.diploma_link,
    id: achievement.id,
    score: achievement.score,
    role: achievement.role,
    status: achievement.status,
    event_id: achievement.event?.id,
    event_title: achievement.event?.title,
    team: achievement.team,
  };
};
