import { withPrefix } from "./utils";
const baseMeta = {
  layout: "mentor",
  auth: true,
  mentor: true,
};

const invites = [
  {
    path: "/m/:user/:code",
    name: "invait-mentor",
    component: () => import("../views/invite/InviteMentor.vue"),
    meta: {
      layout: "reg",
    },
  },
  {
    path: "/a/:code/:org",
    name: "invait-admin",
    component: () => import("../views/invite/InviteAdmin.vue"),
    meta: {
      layout: "reg",
    },
  },
  {
    path: "/o/:code/:org",
    name: "invait-organization",
    component: () => import("../views/invite/InviteOrganization.vue"),
    meta: {
      layout: "reg",
    },
  },
];

const routes = [
  {
    path: "",
    name: "mentor-index",
    component: () => import("../views/mentor/Index.vue"),
    meta: {
      ...baseMeta,
    },
  },
  {
    path: "/organizations",
    name: "mentor-organizations",
    meta: {
      ...baseMeta,
    },
    component: () => import("../views/mentor/Organizations.vue"),
  },
  {
    path: "/organizations/:id",
    name: "mentor-organization",
    meta: {
      ...baseMeta,
    },
    component: () => import("../views/mentor/Organization.vue"),
  },
  {
    path: "/schedule",
    name: "mentor-schedule",
    meta: {
      ...baseMeta,
    },
    component: () => import("../views/mentor/Schedule.vue"),
  },
  {
    path: "/profiles",
    name: "mentor-profiles",
    meta: {
      ...baseMeta,
    },
    component: () => import("../views/mentor/Profiles.vue"),
  },
  {
    path: "/profiles/:id",
    name: "mentor-profile",
    meta: {
      ...baseMeta,
    },
    component: () => import("../views/mentor/Profile.vue"),
  },
  // Страница подопечного ученика
  {
    path: "/student/:id",
    name: "mentor-student",
    meta: {
      ...baseMeta,
    },
    component: () => import("../views/mentor/User.vue"),
  },
  {
    path: "/student/:id/activity/:activity_id",
    name: "mentor-student-activity",
    meta: {
      ...baseMeta,
    },
    component: () => import("../views/mentor/UserActivity.vue"),
  },
  {
    path: "/account",
    name: "mentor-account",
    meta: {
      ...baseMeta,
    },
    component: () => import("../views/Account.vue"),
  },
  {
    path: "/notifications",
    name: "mentor-notifications",
    meta: {
      ...baseMeta,
    },
    component: () => import("../views/notifications/List.vue"),
  },
  {
    path: "/stats",
    name: "mentor-stats",
    meta: {
      ...baseMeta,
    },
    component: () => import("../views/mentor/Stats.vue"),
  },

  {
    path: "/students/achievements",
    name: "students-achievements",
    meta: {
      ...baseMeta,
    },
    component: () => import("../views/mentor/StudentsAchievements.vue"),
  },
];

export const mentorRoutes = withPrefix("/mentor", routes);
export const mentorInvites = withPrefix("/invite", invites);
