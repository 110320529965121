<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-md-offset-3">
        <RoleCard role="school">
          <template #label>Авторизация</template>
          <template #body>
            <div>
              <div class="mb-l">
                <p>Войдите в учетную запись Талант</p>
              </div>
              <BaseButton
                tag="a"
                :href="loginLink"
                >Войти в аккаунт Талант</BaseButton
              >
            </div>
          </template>
        </RoleCard>
      </div>
    </div>
  </div>
</template>

<script>
import RoleCard from "@/components/registration/RoleCard.vue";

export default {
  name: "RegistrationLogin",
  components: {
    RoleCard,
  },
  metaInfo() {
    return {
      title: "Вход в учетную запись.",
    };
  },
  computed: {
    loginLink() {
      let base = `${this.$store.state.clientApiPrefix}/auth/go`;
      if (this.$route.query.next) {
        base += `?next=${encodeURIComponent(this.$route.query.next)}`;
      }
      return base;
    },
  },
};
</script>
