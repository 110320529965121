<template>
  <div>
    <div class="container">
      <RoleCard role="school">
        <template #label>Технические работы</template>
        <template #body>
          <div>
            <div class="mb-l">
              <p>
                Приносим свои извинения, на сайте ведутся технические работы,<br />
                мы будем вновь доступны в течение 2 часов
              </p>
            </div>
          </div>
        </template>
      </RoleCard>
    </div>
  </div>
</template>

<script>
import RoleCard from "@/components/registration/RoleCard.vue";

export default {
  name: "UnderConstruct",
  components: { RoleCard },
  metaInfo() {
    return {
      title: "Личный кабинет временно недоступен",
    };
  },
};
</script>

<style></style>
