import Vue from "vue";
import VModal from "vue-js-modal";
import "./modals.less";
Vue.use(VModal, {
  dialog: false,
  dynamic: true,
  dynamicDefault: {
    draggable: false,
    resizable: false,
    adaptive: true,
    scrollable: true,
    minHeight: 200,
    height: "auto",
  },
});
